import '../App.css';
import MainScreen from '../screens/MainScreen.js';
import Header from '../panels/Header.js';
import Footer from '../panels/Footer.js';
import { Routes, Route } from "react-router-dom";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { AuthScreen, ProfileView, ProfileEdit } from '../screens/AuthScreen.js';
import AuthManager from '../security/authManager';
import { MContext } from '../session/SessionContext';
import { SignOut } from './SignOut';
import Register from '../screens/Register';
import ConfigScreen from '../screens/ConfigScreen';
import ReadoutScreen from '../screens/ReadoutScreen';
import FilterSettings from '../panels/FilterSettings';
import ModePicker from '../panels/ModePicker';
import DashRender from '../panels/DashRender';
import Frequency from '../screens/Frequency';
import PasswordReset from '../screens/PasswordReset';
import ChangePassword from '../panels/ChangePassword';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';

function SessionLoader() {
    const [loaded, setLoaded] = useState(false);
    //todo: set a timer on every 5 minutes to verify the sign in state of the current user.

    const contextValue = useContext(MContext);
    const authObject = contextValue.state.authentication;

    const onProfileReceived = useCallback(profileObject => {
        console.log("Still logged in");
        contextValue.setAuthentication(profileObject);
    }, [contextValue]);

    const onProfileError = error => {
        console.error("Could not log in", error);
        localStorage.removeItem("authentication");
        window.location.reload(false);
    }

    useEffect(() => {
        if (!authObject.username) {
            let lsAuthObject = JSON.parse(localStorage.getItem("authentication"));

            if (lsAuthObject) {
                if (lsAuthObject.username) {
                    AuthManager.getProfile(lsAuthObject.access_token, onProfileReceived, onProfileError);
                }
                else {
                    localStorage.removeItem("authentication");
                    setLoaded(true);
                }
            }
            else {
                setLoaded(true);
            }
        }
        else {
            console.log("Auth has been set")
            if (!loaded) {
                setLoaded(true);
            }
        }
    }, [authObject.username, onProfileReceived, loaded]);

    if (!loaded) {
        return (
            <div className='loading-box fade'>
                Loading X-Ray...
            </div>
        );
    }
    else {
        return (
            <div className="App">
                <div className="app-header">
                    <Header />
                </div>
                <div className='app-content'>
                    <Routes>
                        <Route path="/" element={<MainScreen />} >
                            <Route path="/" element={<ModePicker />} />
                        </Route>
                        <Route path="/register/:reg_token" element={<Register />} />
                        <Route path="/account/psswd/:pass_token" element={<PasswordReset />} />
                        <Route path="/account/forgot" element={<ForgotPasswordScreen />} />
                        <Route path="authenticate" element={<AuthScreen />} >
                            <Route path="/authenticate/" element={<ProfileView />} />
                            <Route path="/authenticate/edit" element={<ProfileEdit />} />
                            <Route path="/authenticate/change_psswd" element={<ChangePassword />} />
                        </Route>
                        <Route path="dash" element={<MainScreen />}>
                            <Route path="/dash/" element={<ModePicker />} />
                            <Route path="/dash/config" element={<FilterSettings />} />
                            <Route path="/dash/render" element={<DashRender />} />
                        </Route>
                        <Route path="frequency" element={<Frequency />} />
                        <Route path="config" element={<ConfigScreen />} />
                        <Route path="readout" element={<ReadoutScreen />} />
                        <Route path="signout" element={<SignOut />} />
                    </Routes>
                </div>
                <div className='app-footer'>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default SessionLoader;