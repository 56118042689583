import './styles/MainScreen.css'
import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';
import React, { useEffect, useState, useContext } from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { MContext } from '../session/SessionContext';
import DashboardObject from '../dashboard/DashboardObject';
import FrequencyPanel from '../panels/FrequencyPanel';

const Frequency = (props) => {
    const navigate = useNavigate();
    const [allow, setAllow] = useState(false);
    const contextValue = useContext(MContext);
    const authObject = contextValue.state.authentication;
    const dashboardStorage = contextValue.state.dashboardStorage;
    const location = useLocation();

    useEffect(() => {
        if (!authObject.username) {
            console.log("Not signed in.");
            navigate("/authenticate?returnUrl=/");
        }
        else {
            setAllow(true);
        }
    }, [authObject, navigate]);

    if (!allow) {
        return (
            <div className='center font-heavy text-dark'>
                Not signed in, you will be redirected...
            </div>
        );
    }
    else {
        return (
            <Row align="stretch" className="main-row">
                <Col span={2}
                    xs={12} sm={12} md={12} lg={2} xl={2} className="side-bar">
                    <div className="filter-bar">
                        <h3 className='mt-0'>Upload frequency</h3>
                        <div>This screen shows exactly how many times a day statistics and events were updated.</div>
                        <div>This page cannot be modified as it spans over all products in the database.</div>
                    </div>
                </Col>
                <Col span={10}
                    xs={12} md={8} lg={9} xl={10}>
                        {/* todo: insert datepicker here. */}
                        <FrequencyPanel />
                </Col>
            </Row>
        );
    }
}

export default Frequency;