
import React, { Component } from "react";
import DashboardObject from "../dashboard/DashboardObject";

export const MContext = React.createContext();

export class SessionContext extends Component {
    constructor(props) {
        super(props);
        let authentication = localStorage.getItem("authentication");
        let signInCounts = localStorage.getItem("signInCounts");
        let rawSearchData = localStorage.getItem("rawSearchData");
        let dashboardStorage = localStorage.getItem("dashboardStorage");
        let dashboardController = new DashboardObject();
        this.state = {
            authentication: {},
            signInCounts: signInCounts !== null ? JSON.parse(signInCounts) : {},
            rawSearchData: rawSearchData !== null ? JSON.parse(rawSearchData) : {},
            dashboardStorage: dashboardStorage !== null ? dashboardController.parseLocalStorageObject(JSON.parse(dashboardStorage)): dashboardController.createEmptyState()
        };
        console.log("Creating new session context");
    }

    render() {
        return (
            <MContext.Provider value={{
                state: this.state,
                setAuthentication: value => {

                    localStorage.setItem("authentication", JSON.stringify(value));

                    this.setState({
                        authentication: value
                    })
                },
                setSignInCounts: value => {
                    localStorage.setItem("signInCounts", JSON.stringify(value));

                    this.setState({
                        signInCounts: value
                    })
                },
                setRawSearchData: value => {
                    localStorage.setItem("rawSearchData", JSON.stringify(value));

                    this.setState({
                        rawSearchData: value
                    })
                },
                setDashboardStorage: value => {
                    localStorage.setItem("dashboardStorage", JSON.stringify(value));

                    this.setState({
                        dashboardStorage: value
                    })
                }
            }}
            >
                {this.props.children}
            </MContext.Provider>
        )
    }
}