class TabColumn {
    constructor(id, name, created, formula, visible) {
        this.id = id;
        this.name = name;
        this.created = created;
        this.formula = formula;
        this.visible = visible;
    }


    exportAsObject() {
        return {
            col_id: this.id,
            col_name: this.name,
            col_created: this.created,
            col_formula: this.formula,
            col_visible: this.visible
        }
    }
}

export default TabColumn;