import './styles/MainScreen.css'
import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';
import React, { useEffect, useState, useContext } from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { MContext } from '../session/SessionContext';
import DashboardObject from '../dashboard/DashboardObject';

const DashboardButtons = (props) => {
    const { boards } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const contextValue = useContext(MContext);
    const dashboardStorage = contextValue.state.dashboardStorage;

    const isConfigMode = () => {
        if (location.pathname.endsWith("config")) {
            return true;
        }

        return false;
    }

    const isRenderMode = () => {
        if (location.pathname.endsWith("render")) {
            return true;
        }

        return false;
    }

    const onDashboardButtonClick = (e) => {
        e.preventDefault();
        let dashboardId = e.target.dataset["dashboardId"];
        dashboardStorage.activeBoard = Number(dashboardId);
        contextValue.setDashboardStorage(dashboardStorage);

        // if we are not in config and we are not in render, do a redirect
        if(!isConfigMode() && !isRenderMode()) {
            // redirect to rendermode
            navigate("/dash/render");
        }
    }

    return (
        <div>
            {
                boards.length !== 0 ?
                    boards.map((item, key) => {
                        if (dashboardStorage.activeBoard === key) {
                            return (
                                <div className='dashboard-button active-board' data-dashboard-id={key} key={key} onClick={onDashboardButtonClick}>
                                    {item.title}
                                </div>
                            );
                        }
                        else {
                            return (
                                <div className='dashboard-button' data-dashboard-id={key} key={key} onClick={onDashboardButtonClick}>
                                    {item.title}
                                </div>
                            );
                        }
                    }) :
                    (
                        <div>No Dashboards created yet!</div>
                    )
            }
        </div>
    );
}

const MainScreen = (props) => {
    const navigate = useNavigate();
    const [allow, setAllow] = useState(false);
    const contextValue = useContext(MContext);
    const authObject = contextValue.state.authentication;
    const dashboardStorage = contextValue.state.dashboardStorage;
    const location = useLocation();

    useEffect(() => {
        if (!authObject.username) {
            console.log("Not signed in.");
            navigate("/authenticate?returnUrl=/");
        }
        else {
            setAllow(true);
        }
    }, [authObject, navigate]);

    const isConfigMode = () => {
        if (location.pathname.endsWith("config")) {
            return true;
        }

        return false;
    }

    const onCreateNewDashboardClick = (e) => {
        e.preventDefault();
        let newDashboard = new DashboardObject().createNew(authObject);
        dashboardStorage.boards.push(newDashboard)
        contextValue.setDashboardStorage(dashboardStorage);
        console.log("Creating new dashboard");
    }

    if (!allow) {
        return (
            <div className='center font-heavy text-dark'>
                Not signed in, you will be redirected...
            </div>
        );
    }
    else {
        return (
            <Row align="stretch" className="main-row">
                <Col span={2}
                    xs={12} sm={12} md={12} lg={2} xl={2} className="side-bar">
                    <div className="filter-bar">
                        <h3 className='mt-0'>Dashboards</h3>
                        <div>Click below button to switch between configuration mode and rendering mode. You can also use the keyboard shortcut Ctrl+K to switch.</div>
                        <div className='dashboard-list'>
                            {isConfigMode() ? (
                                <Link to="/dash/render">
                                    <div className='dashboard-button'>
                                        Render Mode
                                    </div>
                                </Link>
                            ) : (
                                <Link to="/dash/config">
                                    <div className='dashboard-button'>
                                        Configuration Mode
                                    </div>
                                </Link>
                            )}
                            <button className='dashboard-btn' onClick={onCreateNewDashboardClick}>
                                Create new dashboard
                            </button>
                        </div>
                        <div className='dashboard-list'>
                            <p className='dashboard-description'>Pick a dashboard to render</p>
                            <DashboardButtons boards={dashboardStorage.boards} />
                        </div>
                    </div>
                </Col>
                <Col span={10}
                    xs={12} md={8} lg={9} xl={10}>
                    <Outlet context={dashboardStorage} />
                </Col>
            </Row>
        );
    }
}

export default MainScreen;