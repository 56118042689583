class TabFilter {
    constructor(start, end) {
        this.start = start;
        this.end = end;
    }


    exportAsObject() {
        return {
            filter_start: this.start,
            filter_end: this.end
        }
    }
}

export default TabFilter;