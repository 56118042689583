import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import WebHelper, { requestPasswordLink } from '../api/WebHelper';
import { useParams } from "react-router-dom";

const InputField = React.forwardRef(({ label, type, disabled = false, defaultValue = '' }, ref) => {
    return (
        <div>
            <label className='login-label' >{label}</label>
            <input ref={ref} type={type} className='login-input' disabled={disabled} defaultValue={defaultValue} />
        </div>
    );
});

const ForgotPasswordScreen = () => {
    const emailRef = useRef();
    const [message, setMessage] = useState(null);
    const navigate = useNavigate();

    const onSuccess = s => {
        setMessage("Success. You have mail. Make sure to check the spam folder too.");
        setTimeout(() => {
            navigate("/authenticate");
        }, 2000);
    }

    const onError = e => {
        setMessage("There was a problem requesting a password reset. Please try again later. If the problem persists, contact an admin.");
    }

    const onSubmit = e => {
        e.preventDefault();

        let email = emailRef.current.value;
        requestPasswordLink(email, onSuccess, onError);
    };

    return (
        <Row align="stretch" className="main-row">
            <Col span={2}
                xs={12} md={4} lg={3} xl={2} className="side-bar">
                <div className="filter-bar">
                    <h3 className='mt-0'>Request a new Password</h3>
                    <div>Enter your email in the next window to receive a password reset link</div>
                </div>
            </Col>
            <Col span={10}
                xs={12} md={8} lg={9} xl={10}>
                <div className='graph-screen-wrapper'>
                    <form className='overview-box graph-screen' onSubmit={onSubmit} >
                        <InputField ref={emailRef} label="Email" type="email" />
                        <div>
                            <button className='login-button' type="submit">Request password link</button>
                        </div>

                        {message &&
                            <div className='color-red'>
                                {message}
                            </div>}
                    </form>

                </div>
            </Col>
        </Row>
    );
}

export default ForgotPasswordScreen;