import './styles/FilterSettings.css';
import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';
import { useRef, useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { MContext } from '../session/SessionContext';
import { useOutletContext } from "react-router-dom";
import TabColumn from '../dashboard/tabs/TabColumn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import TabVisual from '../dashboard/tabs/TabVisual';
import useKey from '../session/UseKey';
import TabFilter from '../dashboard/tabs/TabFilter';

const VisualizationRenderTypes = [
    { name: "Pie Chart", id: "pie" },
    { name: "Line Chart", id: "line" },
    { name: "Bar Graph", id: "bar" },
    { name: "Top 10", id: "top10" },
    { name: "Deviation Graph", id: "deviation" },
    { name: "Operation Time", id: "optime" },
    { name: "Usage Time", id: "usagetime" }
];

const SortingTypes = [
    { name: "None", id: "none" },
    { name: "Percentage", id: "percentage" },
    { name: "Percentage Reverse", id: "percentage-reverse" },
    { name: "X Axis", id: "x-normal" },
    { name: "X Axis Reversed", id: "x-reverse" },
    { name: "Y Axis", id: "y-normal" },
    { name: "Y Axis Reversed", id: "y-reverse" }
];

const ColEditPanel = (item, key, onDeleteClicked) => {

    const onDeleteClick = (e) => {
        let targetId = e.currentTarget.id;

        onDeleteClicked(targetId.replace("delete-id-", ""));
    };

    return (
        <div className='col-edit-wrapper' data-col-id={item.id} key={key}>
            <div className='col-edit-right col-edit-button' id={'delete-id-' + item.id} onClick={onDeleteClick}><FontAwesomeIcon icon={faTrash} /></div>
            <h4>{item.name}</h4>
            <div>
                <div>
                    <label className='col-edit-form-label' htmlFor={'id-' + item.id}>Column Id</label>
                    <input id={'id-' + item.id} className='search-input edit-id' type="text" defaultValue={item.id} />
                    <input id={'created-' + item.id} className='search-input edit-created' type="hidden" defaultValue={item.created} />
                </div>
                <div>
                    <label className='col-edit-form-label' htmlFor={'name-' + item.id}>Column Name</label>
                    <input id={'name-' + item.id} className='search-input edit-name' type="text" defaultValue={item.name} />
                </div>
                <div>
                    <label className='col-edit-form-label' htmlFor={'formula-' + item.id}>Formula</label>
                    <input id={'formula-' + item.id} className='search-input edit-formula' type="text" defaultValue={item.formula} />
                </div>
                <div>
                    <label className='col-edit-form-label-inline' htmlFor={'visible-' + item.id}>Render Column</label>
                    <input id={'visible-' + item.id} className="edit-visible" type="checkbox" defaultChecked={item.visible} value="test" />
                </div>
            </div>
        </div>)
};

const VisualEditPanel = (item, key, onDeleteClicked) => {

    const onDeleteClick = (e) => {
        let targetId = e.currentTarget.id;

        onDeleteClicked(targetId.replace("delete-id-", ""));
    };

    return (
        <div className='col-edit-wrapper' data-col-id={item.id} key={key}>
            <div className='col-edit-right col-edit-button' id={'delete-id-' + item.id} onClick={onDeleteClick}><FontAwesomeIcon icon={faTrash} /></div>
            <h4>{item.name}</h4>
            <div>
                <div>
                    <label className='col-edit-form-label' htmlFor={'id-' + item.id}>Visualization Id</label>
                    <input id={'id-' + item.id} className='search-input edit-id' type="text" defaultValue={item.id} />
                    <input id={'created-' + item.id} className='search-input edit-created' type="hidden" defaultValue={item.created} />
                </div>
                <div>
                    <label className='col-edit-form-label' htmlFor={'name-' + item.id}>Visualization Name</label>
                    <input id={'name-' + item.id} className='search-input edit-name' type="text" defaultValue={item.name} />
                </div>
                <div>
                    <label className='col-edit-form-label' htmlFor={'target-' + item.id}>Source Column Id</label>
                    <input id={'target-' + item.id} className='search-input edit-target' type="text" defaultValue={item.target} />
                </div>
                <div>
                    <label className='col-edit-form-label' htmlFor={'render-type-' + item.id}>Visualization Type</label>
                    <select id={'render-type-' + item.id} defaultValue={item.renderType} className="search-input edit-render-type">
                        {VisualizationRenderTypes.map((item, key) => {
                            return (<option value={item.id} key={item.id + key}>{item.name}</option>)
                        })}
                    </select>
                </div>
                <div>
                    <label className='col-edit-form-label' htmlFor={'render-sorting-' + item.id}>Sorting Mode</label>
                    <select id={'sorting-type-' + item.id} defaultValue={item.sortingType} className="search-input edit-sorting-type">
                        {SortingTypes.map((item, key) => {
                            return (<option value={item.id} key={item.id + key}>{item.name}</option>)
                        })}
                    </select>
                </div>
                <div>
                    <label className='col-edit-form-label' htmlFor={'x-label-' + item.id}>X-Axis Label</label>
                    <input id={'x-label-' + item.id} className='search-input edit-x-label' type="text" defaultValue={item.labelX} />
                </div>
                <div>
                    <label className='col-edit-form-label' htmlFor={'x-label-' + item.id}>Y-Axis Label</label>
                    <input id={'y-label-' + item.id} className='search-input edit-y-label' type="text" defaultValue={item.labelY} />
                </div>
            </div>
        </div>)
};

const FilterSettings = (props) => {
    let dashboardStorage = useOutletContext();
    let navigate = useNavigate();
    let contextValue = useContext(MContext);

    const columnListRef = useRef();
    const visualsListRef = useRef();
    const dashboardName = useRef();
    const dashboardProduct = useRef();

    const fromYearRef = useRef();
    const fromWeekRef = useRef();
    const toYearRef = useRef();
    const toWeekRef = useRef();

    const [saveMessage, setSaveMessage] = useState(null);

    const board = dashboardStorage.boards[dashboardStorage.activeBoard];

    const onDeleteColumn = (columnId) => {
        console.log("deleting: ", columnId);
        for (let column_id in board.columns) {
            let column = board.columns[column_id];
            if (column.id === columnId) {
                board.columns.splice(column_id, 1);
            }
        }

        dashboardStorage.boards[dashboardStorage.activeBoard] = board;
        contextValue.setDashboardStorage(dashboardStorage);
    };

    const onDeleteVisual = (visualId) => {
        console.log("deleting: ", visualId);
        for (let vis_id in board.visuals) {
            let visual = board.visuals[vis_id];
            if (visual.id === visualId) {
                board.visuals.splice(vis_id, 1);
            }
        }

        dashboardStorage.boards[dashboardStorage.activeBoard] = board;
        contextValue.setDashboardStorage(dashboardStorage);
    };

    const createNewColumn = () => {
        let newCount = 0;
        let uniqueId = false;
        while (!uniqueId) {
            let internalUnique = true;
            for (let colLoop of board.columns) {
                if (colLoop.id === ("new_column_" + newCount)) {
                    internalUnique = false;
                }
            }

            if (!internalUnique) {
                for (let colLoop of board.columns) {
                    if (colLoop.id.includes("new_column")) {
                        if (colLoop.id === ("new_column_" + (newCount + 1))) {
                            newCount = Number(colLoop.id.replace("new_column_", "")) + 1;
                        }
                        else {
                            newCount++;
                        }
                    }
                }
            }
            else {
                uniqueId = true;
            }
        }

        board.columns.push(new TabColumn("new_column_" + newCount, "New Column " + newCount, new Date().toLocaleString(), "", false));
        dashboardStorage.boards[dashboardStorage.activeBoard] = board;
        contextValue.setDashboardStorage(dashboardStorage);
    };

    const createNewVisual = () => {
        let newCount = 0;
        let uniqueId = false;
        while (!uniqueId) {
            let internalUnique = true;
            for (let visLoop of board.visuals) {
                if (visLoop.id === ("new_vis_" + newCount)) {
                    internalUnique = false;
                }
            }

            if (!internalUnique) {
                for (let visLoop of board.visuals) {
                    if (visLoop.id.includes("new_vis")) {
                        if (visLoop.id === ("new_vis_" + (newCount))) {
                            newCount = Number(visLoop.id.replace("new_vis_", "")) + 1;
                        }
                        else {
                            newCount++;
                        }
                    }
                }
            } else {
                uniqueId = true;
            }
        }

        board.visuals.push(new TabVisual("new_vis_" + newCount, "New Visualization " + newCount, new Date().toLocaleString(), "", VisualizationRenderTypes[0].id));
        dashboardStorage.boards[dashboardStorage.activeBoard] = board;
        contextValue.setDashboardStorage(dashboardStorage);
    };

    const onSubmitClicked = () => {
        const columnChildren = columnListRef.current.children;
        let newTabColList = [];

        for (let ch of columnChildren) {
            if (ch.id !== 'no-columns') {
                const columnId = ch.getElementsByClassName('edit-id')[0].value;
                const columnName = ch.getElementsByClassName('edit-name')[0].value;
                const columnFormula = ch.getElementsByClassName('edit-formula')[0].value;
                const columnCreated = ch.getElementsByClassName('edit-created')[0].value;
                const columnVisible = ch.getElementsByClassName('edit-visible')[0].checked;

                //todo: check if this ID has already been used, if so, set error message on current object.

                //create columnObject and add to the list.
                newTabColList.push(new TabColumn(columnId, columnName, columnCreated, columnFormula, columnVisible));
            }
        }

        const visualsChildren = visualsListRef.current.children;
        let newTabVisList = [];
        for (let ch of visualsChildren) {
            if (ch.id !== 'no-visuals') {
                const visId = ch.getElementsByClassName('edit-id')[0].value;
                const visName = ch.getElementsByClassName('edit-name')[0].value;
                const visTarget = ch.getElementsByClassName('edit-target')[0].value;
                const visCreated = ch.getElementsByClassName('edit-created')[0].value;
                const visRenderType = ch.getElementsByClassName('edit-render-type')[0].value;
                newTabVisList.push(new TabVisual(visId, visName, visCreated, visTarget, visRenderType));
            }
        }

        board.title = dashboardName.current.value;
        board.product = dashboardProduct.current.value;

        let fromYear = fromYearRef.current.value;
        let fromWeek = fromWeekRef.current.value;
        let toYear = toYearRef.current.value;
        let toWeek = toWeekRef.current.value;

        let fromString = `${fromYear}${fromWeek}`;
        if (fromString.length !== 4) {
            fromString = null;
        }

        let toString = `${toYear}${toWeek}`;
        if (toString.length !== 4) {
            toString = null;
        }

        board.filter = new TabFilter(fromString, toString);

        board.columns = newTabColList;
        board.visuals = newTabVisList;

        dashboardStorage.boards[dashboardStorage.activeBoard] = board;
        contextValue.setDashboardStorage(dashboardStorage);
        setSaveMessage("Changes saved.");
    };

    useEffect(() => {
        if (board) {
            dashboardName.current.value = board.title;
            dashboardProduct.current.value = board.product;

            fromYearRef.current.value = board.filter?.start?.substring(0, 2);
            fromWeekRef.current.value = board.filter?.start?.substring(2, 4);
            toYearRef.current.value = board.filter?.end?.substring(0, 2);
            toWeekRef.current.value = board.filter?.end?.substring(2, 4);
        }

    }, [board, dashboardStorage, contextValue]);

    const deleteDash = () => {
        dashboardStorage.boards.splice(dashboardStorage.activeBoard, 1);
        dashboardStorage.activeBoard--;
        contextValue.setDashboardStorage(dashboardStorage);
        navigate("/");
    }

    useKey('ctrls', (e) => {
        e.preventDefault();
        onSubmitClicked();
    });

    useKey('ctrlk', (e) => {
        e.preventDefault();
        navigate("/dash/render");
    });


    if (dashboardStorage.activeBoard === -1) {
        return (<div className='graph-screen-wrapper'>
            <div className="graph-screen black-text">No dashboard selected to configure</div>
        </div>);
    }

    return (
        <Row align="stretch" justify="start">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                <div className="graph-screen-wrapper">
                    <div className='graph-screen'>
                        <div className='col-edit-right col-edit-button' id={'delete-id-dash'} onClick={deleteDash}><FontAwesomeIcon className='blue' icon={faTrash} /></div>
                        <h3 className='board-title'>Dashboard Properties</h3>
                        <div>
                            <div>
                                <label className='dash-edit-form-label board-title' htmlFor={'dash-name'}>Dashboard title</label>
                                <input id="dash-name" className='search-input' type="text" defaultValue={board.title} ref={dashboardName} />
                            </div>
                            <div>
                                <label className='dash-edit-form-label board-title' htmlFor={'dash-product'}>Dashboard product</label>
                                <select id='dash-product' defaultValue={board.product} className="search-input" ref={dashboardProduct}>
                                    <option value={"DP001E"}>Wall-E & Eve</option>
                                    <option value={"DP002E"}>Clarity</option>
                                    <option value={"DP003E"}>Tifa</option>
                                    <option value={"DP004E"}>Fred & Barney</option>
                                    <option value={"DP005E"}>Woody & Buzz</option>
                                    <option value={"DP011E"}>Tom & Jerry</option>
                                    <option value={"DR009"}>Connect</option>
                                </select>
                            </div>
                        </div>
                        <div className='graph-screen-submit' onClick={onSubmitClicked}>
                            Save Dashboard changes
                        </div>
                        <div>
                            {saveMessage && <div>{saveMessage}</div>}
                        </div>
                    </div>
                </div>
                <div className="graph-screen-wrapper">
                    <div className='graph-screen'>
                        <h3 className='board-title'>Visualizations</h3>
                        <div ref={visualsListRef}>
                            {
                                board.visuals.length != 0 ?
                                    board.visuals.map((item, key) => VisualEditPanel(item, item.id + key, onDeleteVisual)) :
                                    (
                                        <div id='no-visuals'>No visuals yet</div>
                                    )
                            }
                        </div>
                        <div>
                            <div className='graph-screen-button' onClick={createNewVisual}>
                                Create new Visualization
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                <div className="graph-screen-wrapper">
                    <div className='graph-screen'>
                        <h3 className='board-title'>Filter Settings</h3>
                        <Row align="stretch" justify="start">
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <p className='board-title'><b>From</b></p>
                                <div>
                                    <label className='dash-edit-form-label board-title' htmlFor={'dash-filter-prod-year-from'}>Production Year</label>
                                    <input id="dash-filter-prod-year-from" className='search-input' type="number" ref={fromYearRef} defaultValue={board.filter?.start?.substring(0, 2)} />
                                </div>
                                <div>
                                    <label className='dash-edit-form-label board-title' htmlFor={'dash-filter-prod-week-from'}>Production Week</label>
                                    <input id="dash-filter-prod-week-from" className='search-input' type="number" ref={fromWeekRef} defaultValue={board.filter?.start?.substring(2, 4)} />
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <p className='board-title'><b>To</b></p>
                                <div>
                                    <label className='dash-edit-form-label board-title' htmlFor={'dash-filter-prod-year-from'}>Production Year</label>
                                    <input id="dash-filter-prod-year-from" className='search-input' type="number" ref={toYearRef} defaultValue={board.filter?.end?.substring(0, 2)} />
                                </div>
                                <div>
                                    <label className='dash-edit-form-label board-title' htmlFor={'dash-filter-prod-week-from'}>Production Week</label>
                                    <input id="dash-filter-prod-week-from" className='search-input' type="number" ref={toWeekRef} defaultValue={board.filter?.end?.substring(2, 4)} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="graph-screen-wrapper">
                    <div className='graph-screen'>
                        <h3 className='board-title'>Dashboard data columns</h3>
                        <div ref={columnListRef}>
                            {
                                board.columns.length != 0 ?
                                    board.columns.map((item, key) => ColEditPanel(item, item.id + key, onDeleteColumn)) :
                                    (
                                        <div id='no-columns'>No columns yet</div>
                                    )
                            }
                        </div>
                        <div>
                            <div className='graph-screen-button' onClick={createNewColumn}>
                                Create new Column
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default FilterSettings;