import './styles/Register.css'
import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import WebHelper from '../api/WebHelper';
import { useParams } from "react-router-dom";


const InputField = React.forwardRef(({ label, type, disabled = false, defaultValue = '' }, ref) => {
    return (
        <div>
            <label className='login-label' >{label}</label>
            <input ref={ref} type={type} className='login-input' disabled={disabled} defaultValue={defaultValue} />
        </div>
    );
});

const RegistrationForm = ({ onAccountCreated, regData }) => {
    const passwordRef = React.useRef();
    const passwordConfirmRef = React.useRef();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const navigate = useNavigate();

    const [fPasswordError, setFPasswordError] = useState("");
    const [fPasswordConfirmError, setFPasswordConfirmError] = useState("");

    const onCreateSuccess = data => {
        console.log("account create success: ", data);
        setFPasswordConfirmError("Password has been changed. Redirecting in 3 seconds...");
        setTimeout(() => {
            navigate("/");
        }, 3000);
    }

    const onCreateFail = error => {
        console.log("account create error: ", error);
        setFPasswordConfirmError("Error updating account, contact developer.");
    }

    const handleSubmit = e => {
        e.preventDefault();

        const data = {
            password: passwordRef.current.value,
            passwordConfirm: passwordConfirmRef.current.value
        };


        if (data.password === "") {
            setFPasswordError("Please enter a password");
        }
        else {
            //todo: check password length and if its a strong password
            setFPasswordError("");

            if (data.passwordConfirm === "") {
                setFPasswordConfirmError("Please enter a password");
            }
            else {
                if (data.password !== data.passwordConfirm) {
                    setFPasswordConfirmError("Passwords do not match");
                }
                else {
                    setFPasswordConfirmError("");
                }
            }
        }
        //todo: start the registration. There is a possibility that the username is already taken, make sure to render this error back to the user.
        WebHelper.completePasswordReset(regData.token, JSON.stringify(data), onCreateSuccess, onCreateFail);
        setSubmitDisabled(true);
    };

    return (
        <form className='overview-box graph-screen' onSubmit={handleSubmit} >
            
            <InputField ref={passwordRef} label="Password" type="password" />
            {fPasswordError &&
                <div className='login-error mt-0'>
                    {fPasswordError}
                </div>
            }
            <InputField ref={passwordConfirmRef} label="Confirm Password" type="password" />
            {fPasswordConfirmError &&
                <div className='login-error mt-0'>
                    {fPasswordConfirmError}
                </div>
            }
            <div>
                <button className='login-button' type="submit" disabled={submitDisabled}>{false ? "Sending request" : "Create Account"}</button>
            </div>
        </form>
    );
};

const PasswordReset = (props) => {
    const { pass_token } = useParams();
    const [allow, setAllow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [regData, setRegData] = useState(null);

    // todo: if signed in say that account completion can only happen if signed out.

    const onSuccess = useCallback((data) => {
        setLoading(false);
        if (data.username && data.expires_at) {
            setRegData(data);
            setAllow(true);
        }
    }, []);

    const onError = useCallback((error) => {
        setLoading(false);
    }, []);

    useEffect(() => {
        WebHelper.getResetPasswordParams(pass_token, onSuccess, onError);
    }, []);

    if (loading) {
        return (
            <div className='center font-heavy text-dark'>
                Loading account data.
            </div>
        );
    }

    if (!allow) {
        return (
            <div className='center font-heavy text-dark'>
                Invalid password reset request
            </div>
        );
    }
    else {
        return (
            <Row align="stretch" className="main-row">
                <Col span={2}
                    xs={12} md={4} lg={3} xl={2} className="side-bar">
                    <div className="filter-bar">
                        <h3 className='mt-0'>Password Reset</h3>
                        <div>Reset your password</div>
                    </div>
                </Col>
                <Col span={10}
                    xs={12} md={8} lg={9} xl={10}>
                    <div className='graph-screen-wrapper'>
                        <RegistrationForm regData={regData} />
                    </div>
                </Col>
            </Row>
        );
    }

}

export default PasswordReset;