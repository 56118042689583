import './styles/Header.css';
import React, { useEffect, useRef, useContext, useState } from "react";
import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';
import { Routes, Route, Link } from "react-router-dom";
import lottie from "lottie-web";
import { MContext } from '../session/SessionContext';

const Header = (props) => {
    const container = useRef(null);
    const [authenticated, setAuthenticated] = useState(false);
    const contextValue = useContext(MContext);
    const authObject = contextValue.state.authentication;

    const rightBar = () => {
        if (authenticated) {
            return (<Col span={10}
                xs={12} sm={12} md={12} lg={10} xl={10}>
                <div className='bar-wrapper'>
                    <Row align="middle" justify="space-between">
                        <Col span={6} xs={12} sm={12} md={12} lg={6} xl={6} className='bar-items'>
                            <Row align="middle" justify="start">
                                <Col span={4} xs={12} sm={12} md={6} lg={5} xl={4} className='start'>
                                    <Link to="/" className='router-link'>Dashboards</Link>
                                </Col>
                                <Col span={4} xs={12} sm={12} md={6} lg={5} xl={4} className='start'>
                                    <Link to="/readout" className='router-link'>Raw Product Data</Link>
                                </Col>
                                <Col span={4} xs={12} sm={12} md={6} lg={5} xl={4} className='start'>
                                    <Link to="/frequency" className='router-link'>Data Frequency</Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6} xs={12} sm={12} md={12} lg={6} xl={6} className='bar-items'>
                            <Row align="middle" justify="end">
                                <Col span={3} xs={12} sm={12} md={4} lg={4} xl={3} className='left'>
                                    Insights
                                </Col>

                                {authObject.roles.includes("ROLE_ADMIN") &&
                                    <Col span={3} xs={12} sm={12} md={4} lg={4} xl={3} className='left'>
                                        <Link to="/config" className='router-link'>User Config</Link>
                                    </Col>}

                                <Col span={3} xs={12} sm={12} md={4} lg={4} xl={3} className='left'>
                                    <Link to="/authenticate" className='router-link'>Account</Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Col>);
        } else {
            return (<Col span={10}
                xs={12} md={10} lg={10} xl={10}>
                <div className='bar-wrapper'>
                    <Row>
                        <Col span={4} className='bar-items'>
                            <Row>
                                <Col span={6} className='start'>
                                    <Link to="/" className='router-link'>Home</Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col offset={3} span={5} className='bar-items'>
                            <Row>

                                <Col offset={8} span={4} className='center'>
                                    <Link to="/authenticate" className='router-link'>Login</Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Col>);
        }
    }

    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
            renderer: "svg",
            loop: false,
            autoplay: false,
            animationData: require("../lottie/xray_icon_animation.json")
        });

        return () => {
            lottie.destroy();
        };
    }, []);

    useEffect(() => {
        if (!authObject.username) {
            console.log("Not signed in.");
        }
        else {
            setAuthenticated(true);
        }
    }, [contextValue]);

    return (
        <Row align="left" justify="stretch">
            <Col span={2} className="image-wrapper"
                xs={12} sm={12} md={12} lg={2} xl={2}>
                <div
                    className='image-icon'
                    ref={container}
                    onMouseEnter={() => {
                        lottie.setDirection(1);
                        lottie.play()
                    }}
                    onMouseLeave={() => {
                        lottie.setDirection(-1);
                        lottie.play()
                    }}
                />
            </Col>
            {rightBar()}
        </Row>
    );
}

export default Header;