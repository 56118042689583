import React, { useEffect, useState, useContext, useRef } from "react";
import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';
import { VictoryPie, VictoryTheme, VictoryLabel, VictoryChart, VictoryLine, VictoryBar, VictoryAxis, VictoryGroup, VictoryTooltip } from "victory";

const PieVisual = (props) => {
    const { dataSource, title } = props;
    const [pieData, setPieData] = useState(null);
    const [recordCount, setRecordCount] = useState(0);

    useEffect(() => {
        if (dataSource !== null && dataSource !== undefined) {
            let newPieData = [];
            //todo: check if the data passed to function is in correct state.

            let totalProductCount = 0;
            for (let rec of dataSource) {

                if (rec.count === undefined) {

                    totalProductCount = 0;
                    break;
                }

                totalProductCount = totalProductCount + rec.count;
            }

            let otherObjects = [];

            for (let recordId in dataSource) {
                const record = dataSource[recordId];

                if (record.count === undefined) {
                    newPieData = null;
                    break;
                }

                const percent = Math.round(record.count / totalProductCount * 100);

                if (percent <= 5) {
                    otherObjects.push({
                        x: record.name,
                        y: record.count,
                        //label: record.name,
                        percentage: percent
                    });
                }
                else {
                    newPieData.push({
                        x: record.name,
                        y: record.count,
                        //label: record.name,
                        percentage: percent
                    });
                }
            }

            //if there are other objects, add them up and create a total for other categories.
            if (otherObjects.length !== 0) {
                let otherCount = 0;
                let otherPercentage = 0;
                for (let ob of otherObjects) {
                    otherCount = otherCount + ob.y;
                    otherPercentage = otherPercentage + ob.percentage;
                }

                newPieData.push({
                    x: "Other",
                    y: otherCount,
                    //label: record.name,
                    percentage: otherPercentage
                });
            }

            // before returning, sort the x-axis.
            newPieData.sort((a, b) => {
                const nameA = a.percentage;
                const nameB = b.percentage;
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            }).reverse();

            setRecordCount(totalProductCount);
            setPieData(newPieData);
        } else {
            setPieData(null);
        }
    }, [dataSource]);

    if (pieData !== null) {
        return (
            <svg viewBox="0 0 400 390">
                <VictoryLabel x={0} y={10}
                    text={title}
                    style={{
                        textAnchor: "start",
                        fill: "#000",
                        fontFamily: "inherit",
                        fontWeight: "bold",
                        fontSize: 20
                    }}
                />
                <VictoryLabel x={5} y={30}
                    text={"Total Products: " + recordCount}
                    style={{
                        textAnchor: "start",
                        fill: "#5C798C",
                        fontFamily: "inherit",
                        fontSize: 13
                    }}
                />
                <VictoryPie
                    animate={{
                        duration: 2000
                    }}
                    data={pieData}
                    theme={VictoryTheme.material}
                    standalone={false}
                    origin={{ x: 200, y: 200 }}
                    radius={140}
                    labels={({ datum }) => `${datum.x}`}
                    labelPosition={"centroid"}
                    style={{
                        data: {
                            fillOpacity: 1, stroke: "#5C798C", strokeWidth: 2
                        },
                        labels: {
                            fontSize: 13, fill: "#5C798C"
                        }
                    }}
                />
                <VictoryPie
                    data={pieData}
                    labelRadius={60}
                    theme={VictoryTheme.grayscale}
                    standalone={false}
                    labels={({ datum }) => `${datum.percentage}%`}
                    origin={{ x: 200, y: 200 }}
                    innerRadius={50}
                    cornerRadius={7}
                    radius={100}
                    style={{
                        data: {
                            fillOpacity: 0.4,
                            stroke: "#5C798C",
                            fill: "#000",
                            strokeWidth: 2,
                        },
                        labels: {
                            fontSize: 15, fill: "#f9f7f7"
                        }
                    }}
                    labelPlacement={"parallel"}
                />
            </svg>
        );
    }
    else {
        return (
            <div>Data loading</div>
        )
    }
}

const LineVisual = (props) => {
    const { dataSource, title, target } = props;
    const [linesData, setLinesData] = useState(null);
    const [recordCount, setRecordCount] = useState(0);

    const lineColors = ["#00796B", "#F4511E", "#FFF59D", "#DCE775", "#8BC34A"];

    const getLineColor = (id) => {
        return lineColors[id % lineColors.length];
    }

    useEffect(() => {
        if (dataSource !== null && dataSource !== undefined) {
            let splitTarget = target.split(',');
            let newLinesData = [];
            for (let t of splitTarget) {
                let subTarget = t.trim();
                let lineDataSource = dataSource[subTarget];
                if (lineDataSource !== undefined && lineDataSource !== null) {
                    setRecordCount(lineDataSource.length);
                    let newLineData = [];
                    for (let recId in lineDataSource) {
                        let rec = lineDataSource[recId];
                        newLineData.push({
                            x: recId,
                            y: Number(rec)
                        });
                    }

                    // before returning, sort the x-axis.
                    newLineData.sort((a, b) => {
                        const nameA = a.x;
                        const nameB = b.x;
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    }).reverse();

                    newLinesData.push(newLineData);
                }
                else {
                    console.log("could not find column: ", subTarget);
                    newLinesData.push([{ x: 1, y: 1 }]);
                }
            }


            setLinesData(newLinesData);
        }
        else {
            setLinesData(null);
        }
    }, []);

    if (linesData !== null) {
        return (
            <VictoryChart
                height={390}
                width={400}
                theme={VictoryTheme.material}>
                <VictoryLabel x={0} y={10}
                    text={title}
                    style={{
                        textAnchor: "start",
                        fill: "#000",
                        fontFamily: "inherit",
                        fontWeight: "bold",
                        fontSize: 20
                    }}
                />
                <VictoryLabel x={5} y={30}
                    text={"Total Products: " + recordCount}
                    style={{
                        textAnchor: "start",
                        fill: "#5C798C",
                        fontFamily: "inherit",
                        fontSize: 13
                    }}
                />
                {
                    linesData.map((item, key) => {
                        return (
                            <VictoryLine
                                key={key}
                                style={{
                                    data: { stroke: getLineColor(key) },
                                    parent: { border: "1px solid #ccc" }
                                }}
                                data={item}
                            />
                        )
                    })
                }
            </VictoryChart>
        );
    }
    else {
        return (
            <div>Data loading</div>
        )
    }
}

const BarVisual = (props) => {
    const { dataSource, title, target } = props;
    const [barData, setBarData] = useState(null);
    const [recordCount, setRecordCount] = useState(0);

    const barColors = ["#00796B", "#F4511E", "#FFF59D", "#DCE775", "#8BC34A"];

    const getBarColor = (id) => {
        return barColors[id % barColors.length];
    }

    useEffect(() => {
        // process data
        const currentTarget = dataSource[target];

        let newbarData = [];
        let totalProductCount = 0;

        for (let rec of currentTarget) {

            if (rec.count === undefined) {

                totalProductCount = 0;
                break;
            }

            totalProductCount = totalProductCount + rec.count;
        }

        for (let recordId in currentTarget) {
            const record = currentTarget[recordId];

            if (record.count === undefined) {
                newbarData = null;
                break;
            }

            const percent = Math.round(record.count / totalProductCount * 100);
            newbarData.push({
                x: record.name,
                y: record.count,
                id: recordId,
                percentage: percent
            });
        }

        // before returning, sort the x-axis.
        newbarData.sort((a, b) => {
            const nameA = a.percentage;
            const nameB = b.percentage;
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        }).reverse();

        setRecordCount(totalProductCount);
        setBarData(newbarData);
    }, [dataSource, target]);

    if (barData !== null && barData !== undefined) {
        return (
            <svg viewBox="0 0 400 390">
                <VictoryLabel x={0} y={10}
                    text={title}
                    style={{
                        textAnchor: "start",
                        fill: "#000",
                        fontFamily: "inherit",
                        fontWeight: "bold",
                        fontSize: 20,
                    }}
                />
                <VictoryLabel x={5} y={30}
                    text={"Total Products: " + recordCount}
                    style={{
                        textAnchor: "start",
                        fill: "#5C798C",
                        fontFamily: "inherit",
                        fontSize: 13
                    }}
                />

                <VictoryChart domainPadding={30}
                    width={400} height={390}
                    standalone={false}>
                    <VictoryBar
                        style={{
                            data:
                            {
                                fill: (({ datum }) => getBarColor(datum.id)),
                                stroke: "#5C798C", strokeWidth: 2
                            }
                        }}
                        data={barData}
                        padding={60}
                        standalone={false}
                        labels={({ datum }) => `${datum.y}`}
                        labelComponent={<VictoryLabel
                            dy={-5}
                            textAnchor="middle"
                            style={{
                                fill: "#5C798C"
                            }}
                            verticalAnchor="end" />}
                    />
                </VictoryChart>
            </svg>
        );
    }
    else {
        return (<div>No information available</div>)
    }
};

const DeviationVisual = (props) => {
    const { dataSource, title, target } = props;
    const [devData, setDevData] = useState(null);
    const [barData, setBarData] = useState(null);
    const [lineData, setLineData] = useState(null);
    const [recordCount, setRecordCount] = useState(0);

    const barColors = ["#00796B", "#F4511E", "#FFF59D", "#DCE775", "#8BC34A"];

    const getBarColor = (id) => {
        return barColors[id % barColors.length];
    }

    const calcStandardDeviation = (average, deviation, binValues) => {

        let height = Math.max.apply(null, binValues);

        let newLineData = [];
        //first add the average
        newLineData.push({
            x: average,
            y: height
        });

        for (let i = 1; i < 4; i++) {
            newLineData.push({
                x: average + (deviation * i),
                y: height - (i * (height / 3))
            });
        }

        for (let i = 1; i < 4; i++) {
            newLineData.push({
                x: average - (deviation * i),
                y: height - (i * (height / 3))
            });
        }
        console.log("new line data", newLineData);
        return newLineData;
    }

    const calcGauss = (x, average, deviation, sampleSize) => {
        let gaussX = 10 * (x - 0.5) / sampleSize;
        let gaussY = 1 * deviation / (Math.sqrt(2 * Math.PI)) * Math.pow(-0.5 * gaussX, 2);

        return gaussY;
    }

    const range = (start, stop, step) => {
        step = step || 1;
        var arr = [];
        for (var i = start; i < stop; i += step) {
            arr.push(i);
        }
        return arr;
    }

    useEffect(() => {
        // process data
        const currentTarget = dataSource[target][0];
        let newBarData = [];

        for (let binId in currentTarget.binValues) {
            newBarData.push({
                x: currentTarget.binSize + (binId * currentTarget.binSize),
                y: currentTarget.binValues[binId],
                id: binId
            });
        }

        // before returning, sort the x-axis.
        newBarData.sort((a, b) => {
            const nameA = a.y.toUpperCase();
            const nameB = b.y.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        let gaussX = range(-99, 100, 1);
        let gaussY = [];
        let newLineData = [];

        for (let valId in gaussX) {
            let val = gaussX[valId];
            gaussX[valId] = 10 * (val - 0.5) / gaussX.length;
        }

        for (let valId in gaussX) {
            let val = gaussX[valId];
            gaussY[valId] = 1 * (gaussX[1] - gaussX[0]) / (Math.sqrt(2 * Math.PI)) * Math.pow(Math.E, -0.5 * Math.pow(val, 2));
        }

        for (let valId in gaussX) {
            let x = gaussX[valId];
            let y = gaussY[valId];

            newLineData.push({
                x: x * currentTarget.deviation + currentTarget.average,
                y: y * currentTarget.binValues.length * currentTarget.binSize / (currentTarget.deviation * (gaussX[1] - gaussX[0]))
            });

            gaussX[valId] = x * currentTarget.deviation + currentTarget.average;
            gaussY[valId] = y * currentTarget.binValues.length * currentTarget.binSize / (currentTarget.deviation * gaussX[1] - gaussX[0]);
        }

        setDevData(currentTarget);
        setRecordCount(currentTarget.total);
        setLineData(newLineData);
        setBarData(newBarData);
    }, [dataSource, target]);

    if (barData !== null && barData !== undefined) {
        return (
            <svg viewBox="0 0 400 390">
                <VictoryLabel x={0} y={10}
                    text={title}
                    style={{
                        textAnchor: "start",
                        fill: "#000",
                        fontFamily: "inherit",
                        fontWeight: "bold",
                        fontSize: 20
                    }}
                />

                <VictoryLabel x={5} y={30}
                    text={"Total Products: " + recordCount}
                    style={{
                        textAnchor: "start",
                        fill: "#5C798C",
                        fontFamily: "inherit",
                        fontSize: 13
                    }}
                />

                <VictoryChart domainPadding={5}
                    width={400} height={390}
                    standalone={false}>
                    <VictoryBar
                        style={{
                            data:
                            {
                                fill: (({ datum }) => getBarColor(datum.id)),
                                stroke: "#5C798C", strokeWidth: 2
                            }
                        }}
                        data={barData}
                        standalone={false}
                    />
                    <VictoryLine
                        interpolation="natural"
                        style={{ data: { stroke: "darkRed" } }}
                        data={lineData}
                    />
                </VictoryChart>
            </svg>
        );
    }
    else {
        return (<div>No information available</div>)
    }
};

const Top10Visual = (props) => {
    const { dataSource, title, target } = props;
    const [barData, setBarData] = useState(null);
    const [barMax, setBarMax] = useState(0);

    const barColors = ["#00796B", "#F4511E", "#FFF59D", "#DCE775", "#8BC34A"];

    const getBarColor = (id) => {
        return barColors[id % barColors.length];
    }

    useEffect(() => {
        // process data
        const currentTarget = dataSource[target];

        let newBarData = [];
        let newHighest = 0;

        for (let recordId in currentTarget) {
            const record = currentTarget[recordId];

            if (record.count === undefined) {
                newBarData = null;
                break;
            }

            if (record.count > newHighest) {
                newHighest = record.count;
            }

            newBarData.push({
                x: record.name,
                y: record.count,
                id: recordId
            });
        }
        setBarMax(newHighest);
        setBarData(newBarData);

    }, [dataSource, target]);

    if (barData !== null && barData !== undefined) {
        return (
            <div className="inline-chart">
                <svg viewBox="0 0 400 390">
                    <VictoryLabel x={0} y={10}
                        text={title}
                        style={{
                            textAnchor: "start",
                            fill: "#000",
                            fontFamily: "inherit",
                            fontWeight: "bold",
                            fontSize: 20
                        }}
                    />

                    <VictoryChart domainPadding={{ x: [10, 10] }}
                        width={400} height={390}
                        standalone={false}>
                        <VictoryAxis />
                        <VictoryBar
                            horizontal
                            style={{
                                data:
                                {
                                    fill: (({ datum }) => getBarColor(datum.id)),
                                    stroke: "#5C798C", strokeWidth: 2
                                }
                            }}
                            data={barData}
                            domain={{ y: [barMax * -5, barMax] }}
                            standalone={false}
                            labels={({ datum }) => `${datum.y}`}
                        />
                    </VictoryChart>
                </svg>
            </div>
        );
    }
    else {
        return (<div>No information available</div>)
    }
};

const OperationTimeVisual = (props) => {
    const { dataSource, title, target } = props;
    const [barDataLeft, setBarDataLeft] = useState(null);
    const [barDataRight, setBarDataRight] = useState(null);
    const [recordCount, setRecordCount] = useState(0);

    const barColors = ["#00796B", "#F4511E", "#FFF59D", "#DCE775", "#8BC34A"];

    const getBarColor = (id) => {
        return barColors[id % barColors.length];
    }

    useEffect(() => {
        // process data
        const currentTarget = dataSource[target];

        let newbarData = [];
        let totalProductCount = currentTarget.length;

        // loop over each product, and calculate for left the average of each record.
        let averageLeft = {};
        let averageRight = {};
        for (let product of currentTarget) {
            const entriesLeft = Object.entries(product.LEFT);
            const entriesRight = Object.entries(product.RIGHT);

            // loop through left:
            for (let leftEntry of entriesLeft) {
                averageLeft[leftEntry[0]] = averageLeft[leftEntry[0]] ? averageLeft[leftEntry[0]] + leftEntry[1] : leftEntry[1];
            }

            for (let rightEntry of entriesRight) {
                averageRight[rightEntry[0]] = averageRight[rightEntry[0]] ? averageRight[rightEntry[0]] + rightEntry[1] : rightEntry[1];
            }
        }

        let namingArray = ["Idle", "Call", "A2DP", "DM", "Charging"]

        // go through average left entries and devide by total: 
        const leftAverageEntries = Object.entries(averageLeft);
        for (let averageEntry of leftAverageEntries) {
            averageLeft[averageEntry[0]] = averageEntry[1] / totalProductCount;
        }

        const rightAverageEntries = Object.entries(averageRight);
        for (let averageEntry of rightAverageEntries) {
            averageRight[averageEntry[0]] = averageEntry[1] / totalProductCount;
        }

        let newLeftData = [];
        let newRightData = [];

        for (let index = 0; index < namingArray.length; index++) {
            newLeftData.push({
                x: namingArray[index],
                y: averageLeft[leftAverageEntries[index][0]],
                id: index
            });
        }

        for (let index = 0; index < namingArray.length; index++) {
            newRightData.push({
                x: namingArray[index],
                y: averageRight[rightAverageEntries[index][0]],
                id: index
            });
        }

        setRecordCount(totalProductCount);
        setBarDataLeft(newLeftData);
        setBarDataRight(newRightData);
    }, [dataSource, target]);

    if (barDataLeft !== null && barDataLeft !== undefined) {
        return (
            <svg viewBox="0 0 400 390">
                <VictoryLabel x={0} y={10}
                    text={title}
                    style={{
                        textAnchor: "start",
                        fill: "#000",
                        fontFamily: "inherit",
                        fontWeight: "bold",
                        fontSize: 20,
                    }}
                />
                <VictoryLabel x={5} y={30}
                    text={"Total Products: " + recordCount}
                    style={{
                        textAnchor: "start",
                        fill: "#5C798C",
                        fontFamily: "inherit",
                        fontSize: 13
                    }}
                />

                <VictoryChart domainPadding={25}
                    width={400} height={390}
                    standalone={false}>
                    <VictoryAxis fixLabelOverlap />
                    <VictoryAxis dependentAxis name="% of usage"
                        label={"% of usage"} />
                    <VictoryGroup
                        offset={15}
                        colorScale={"qualitative"}
                    >
                        <VictoryBar
                            style={{
                                data:
                                {
                                    fill: (({ datum }) => getBarColor(datum.id)),
                                    stroke: "#5C798C", strokeWidth: 2
                                }
                            }}
                            data={barDataLeft}
                            padding={60}
                            standalone={false}
                            labels={({ datum }) => `L`}
                            labelComponent={<VictoryLabel
                                dy={-5}
                                textAnchor="middle"
                                style={{
                                    fill: "#5C798C"
                                }}
                                verticalAnchor="end" />}

                        />
                        <VictoryBar
                            style={{
                                data:
                                {
                                    fill: (({ datum }) => getBarColor(datum.id)),
                                    stroke: "#5C798C", strokeWidth: 2
                                }
                            }}
                            data={barDataRight}
                            padding={60}
                            standalone={false}
                            labels={({ datum }) => `R`}
                            labelComponent={<VictoryLabel
                                dy={-5}
                                textAnchor="middle"
                                style={{
                                    fill: "#5C798C",

                                }}
                                verticalAnchor="end" />}

                        />
                    </VictoryGroup>

                </VictoryChart>
                <VictoryChart domainPadding={25}
                    width={400} height={390}
                    standalone={false}>
                    <VictoryAxis fixLabelOverlap />
                    <VictoryAxis dependentAxis name="% of usage"
                        label={"% of usage"} />
                    <VictoryGroup
                        offset={15}
                        colorScale={"qualitative"}
                    >
                        <VictoryBar
                            style={{
                                data:
                                {
                                    fill: (({ datum }) => getBarColor(datum.id)),
                                    stroke: "#5C798C", strokeWidth: 2
                                }
                            }}
                            data={barDataLeft}
                            padding={60}
                            standalone={false}
                            labelComponent={<VictoryTooltip
                                cornerRadius={5}
                                pointerLength={20}
                                flyoutStyle={{
                                    stroke: "tomato"
                                }}
                            />}
                            labels={({ datum }) => `${Math.round(datum.y * 100) / 100}%`}

                        />
                        <VictoryBar
                            style={{
                                data:
                                {
                                    fill: (({ datum }) => getBarColor(datum.id)),
                                    stroke: "#5C798C", strokeWidth: 2
                                }
                            }}
                            data={barDataRight}
                            padding={60}
                            standalone={false}
                            labelComponent={<VictoryTooltip
                                cornerRadius={5}
                                pointerLength={20}
                                flyoutStyle={{
                                    stroke: "tomato"
                                }}
                            />}
                            labels={({ datum }) => `${Math.round(datum.y * 100) / 100}%`}

                        />
                    </VictoryGroup>

                </VictoryChart>
            </svg>
        );
    }
    else {
        return (<div>No information available</div>)
    }
};

const UsageTimeVisual = (props) => {
    const { dataSource, title, target } = props;
    const [barDataLeft, setBarDataLeft] = useState(null);
    const [barDataRight, setBarDataRight] = useState(null);
    const [recordCount, setRecordCount] = useState(0);

    const barColors = ["#00796B", "#F4511E", "#FFF59D", "#DCE775", "#8BC34A"];

    const getBarColor = (id) => {
        return barColors[id % barColors.length];
    }

    useEffect(() => {
        // process data
        const currentTarget = dataSource[target];

        let newbarData = [];
        let totalProductCount = currentTarget.length;

        // loop over each product, and calculate for left the average of each record.
        let averageLeft = {};
        let averageRight = {};
        for (let product of currentTarget) {
            const entriesLeft = Object.entries(product.LEFT);
            const entriesRight = Object.entries(product.RIGHT);

            // loop through left:
            for (let leftEntry of entriesLeft) {
                averageLeft[leftEntry[0]] = averageLeft[leftEntry[0]] ? averageLeft[leftEntry[0]] + leftEntry[1] : leftEntry[1];
            }

            for (let rightEntry of entriesRight) {
                averageRight[rightEntry[0]] = averageRight[rightEntry[0]] ? averageRight[rightEntry[0]] + rightEntry[1] : rightEntry[1];
            }
        }

        let namingArray = ["Call", "A2DP", "DM"];

        // go through average left entries and devide by total: 
        const leftAverageEntries = Object.entries(averageLeft);
        for (let averageEntry of leftAverageEntries) {
            averageLeft[averageEntry[0]] = averageEntry[1] / totalProductCount;
        }

        const rightAverageEntries = Object.entries(averageRight);
        for (let averageEntry of rightAverageEntries) {
            averageRight[averageEntry[0]] = averageEntry[1] / totalProductCount;
        }

        let newLeftData = [];
        let newRightData = [];

        for (let index = 0; index < namingArray.length; index++) {
            newLeftData.push({
                x: namingArray[index],
                y: averageLeft[leftAverageEntries[index][0]],
                id: index
            });
        }

        for (let index = 0; index < namingArray.length; index++) {
            newRightData.push({
                x: namingArray[index],
                y: averageRight[rightAverageEntries[index][0]],
                id: index
            });
        }

        setRecordCount(totalProductCount);
        setBarDataLeft(newLeftData);
        setBarDataRight(newRightData);
    }, [dataSource, target]);

    if (barDataLeft !== null && barDataLeft !== undefined) {
        return (
            <svg viewBox="0 0 400 390">
                <VictoryLabel x={0} y={10}
                    text={title}
                    style={{
                        textAnchor: "start",
                        fill: "#000",
                        fontFamily: "inherit",
                        fontWeight: "bold",
                        fontSize: 20,
                    }}
                />
                <VictoryLabel x={5} y={30}
                    text={"Total Products: " + recordCount}
                    style={{
                        textAnchor: "start",
                        fill: "#5C798C",
                        fontFamily: "inherit",
                        fontSize: 13
                    }}
                />

                <VictoryChart domainPadding={25}
                    width={400} height={390}
                    standalone={false}>
                    <VictoryAxis fixLabelOverlap />
                    <VictoryAxis dependentAxis name="% of usage"
                        label={"% of usage"} />
                    <VictoryGroup
                        offset={25}
                        colorScale={"qualitative"}
                    >
                        <VictoryBar
                            style={{
                                data:
                                {
                                    fill: (({ datum }) => getBarColor(datum.id)),
                                    stroke: "#5C798C", strokeWidth: 2
                                }
                            }}
                            data={barDataLeft}
                            padding={60}
                            standalone={false}
                            labels={({ datum }) => `L`}
                            labelComponent={<VictoryLabel
                                dy={-5}
                                textAnchor="middle"
                                style={{
                                    fill: "#5C798C"
                                }}
                                verticalAnchor="end" />}

                        />

                        <VictoryBar
                            style={{
                                data:
                                {
                                    fill: (({ datum }) => getBarColor(datum.id)),
                                    stroke: "#5C798C", strokeWidth: 2
                                }
                            }}
                            data={barDataRight}
                            padding={60}
                            standalone={false}
                            labels={({ datum }) => `R`}
                            labelComponent={<VictoryLabel
                                dy={-5}
                                textAnchor="middle"
                                style={{
                                    fill: "#5C798C",

                                }}
                                verticalAnchor="end" />}

                        />
                    </VictoryGroup>

                </VictoryChart>
                <VictoryChart domainPadding={25}
                    width={400} height={390}
                    standalone={false}>
                    <VictoryAxis fixLabelOverlap />
                    <VictoryAxis dependentAxis name="% of usage"
                        label={"% of usage"} />
                    <VictoryGroup
                        offset={25}
                        colorScale={"qualitative"}
                    >
                        <VictoryBar
                            style={{
                                data:
                                {
                                    fill: (({ datum }) => getBarColor(datum.id)),
                                    stroke: "#5C798C", strokeWidth: 2
                                }
                            }}
                            data={barDataLeft}
                            padding={60}
                            standalone={false}
                            labelComponent={<VictoryTooltip
                                cornerRadius={5}
                                pointerLength={20}
                                flyoutStyle={{
                                    stroke: "tomato"
                                }}
                            />}
                            labels={({ datum }) => `${Math.round(datum.y * 100) / 100}%`}

                        />

                        <VictoryBar
                            style={{
                                data:
                                {
                                    fill: (({ datum }) => getBarColor(datum.id)),
                                    stroke: "#5C798C", strokeWidth: 2
                                }
                            }}
                            data={barDataRight}
                            padding={60}
                            standalone={false}
                            labelComponent={<VictoryTooltip
                                cornerRadius={5}
                                pointerLength={20}
                                flyoutStyle={{
                                    stroke: "tomato"
                                }}
                            />}
                            labels={({ datum }) => `${Math.round(datum.y * 100) / 100}%`}

                        />
                    </VictoryGroup>

                </VictoryChart>
            </svg>
        );
    }
    else {
        return (<div>No information available</div>)
    }
};

const visualSwitcher = (visual, data) => {

    let renderObject = <div>Invalid render type</div>

    switch (visual.renderType) {
        case 'pie':
            renderObject = <PieVisual id={visual.id} dataSource={data[visual.target]} title={visual.name} />;
            break;
        case 'line':
            renderObject = <LineVisual id={visual.id} dataSource={data} title={visual.name} target={visual.target} />;
            break;
        case 'bar':
            renderObject = <BarVisual id={visual.id} dataSource={data} title={visual.name} target={visual.target} />;
            break;
        case 'deviation':
            renderObject = <DeviationVisual id={visual.id} dataSource={data} title={visual.name} target={visual.target} />;
            break;
        case 'top10':
            renderObject = <Top10Visual id={visual.id} dataSource={data} title={visual.name} target={visual.target} />;
            break;
        case 'optime':
            renderObject = <OperationTimeVisual id={visual.id} dataSource={data} title={visual.name} target={visual.target} />;
            break;
        case 'usagetime':
            renderObject = <UsageTimeVisual id={visual.id} dataSource={data} title={visual.name} target={visual.target} />;
            break;
    }

    return (
        <div className='dashboard-chart'>
            {
                renderObject
            }
        </div>
    )
};

const Visualizer = (props) => {
    const { dashboardData, boardVisuals, boardColumns } = props;
    return (
        <Row align="stretch" justify="start">
            {
                boardVisuals.length !== 0 ?
                    boardVisuals.map((item, key) => {
                        return (
                            <Col span={3}
                                xs={12} sm={6} md={6} lg={4} xl={3} xxl={3} xxxl={3} xxxxl={2}
                                className="dashboard-chart-wrapper" key={item.id} >
                                {visualSwitcher(item, dashboardData)}
                            </Col>
                        );
                    }) :
                    (
                        <Col span={10}
                            xs={12} md={12} lg={12} xl={12}
                            className="dashboard-chart-wrapper">
                            <div className='dashboard-chart'>
                                <h3 className="board-title">No Visuals exist for this dashboard</h3>
                            </div>
                        </Col>
                    )
            }
        </Row>
    );
}

export default Visualizer;