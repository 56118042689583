import TabColumn from "./tabs/TabColumn";
import TabFilter from "./tabs/TabFilter";
import TabObject from "./tabs/TabObject";
import TabVisual from "./tabs/TabVisual";

class DashboardObject {
    constructor() {

    }

    parseJsonString(data) {
        let p = JSON.parse(data);
        let tabFilter = new TabFilter(p.tab_filter.filter_start, p.tab_filter.filter_end);
        let columns = [];
        let visuals = [];

        for (let tabColId in p.tab_col) {
            let t = p.tab_col[tabColId];
            columns.push(new TabColumn(t.col_id, t.col_name, t.col_created, t.col_formula, t.col_visible));
        }

        for (let visualId in p.col_visuals) {
            let v = p.col_visuals[visualId];
            visuals.push(new TabVisual(v.vis_id, v.vis_name, v.vis_created, v.vis_target, v.vis_render_type));
        }

        return new TabObject(p.tab_title, p.tab_created, p.tab_product, p.tab_creator, columns, visuals, tabFilter);
    }

    convertTabDataToObject(data) {
        let p = data;
        let { filter } = p;
        if (!filter) {
            filter = { start: null, end: null };
        }
        let tabFilter = new TabFilter(filter.start, filter.end);
        let columns = [];
        let visuals = [];

        for (let tabColId in p.columns) {
            let t = p.columns[tabColId];
            columns.push(new TabColumn(t.id, t.name, t.created, t.formula, t.visible));
        }

        for (let visualId in p.visuals) {
            let v = p.visuals[visualId];
            visuals.push(new TabVisual(v.id, v.name, v.created, v.target, v.renderType));
        }

        return new TabObject(p.title, p.created, p.product, p.creator, columns, visuals, tabFilter);
    }

    parseLocalStorageObject(localStorageObject) {
        const { activeBoard, configModeActive, boards } = localStorageObject;
        console.log("Parsing: ", localStorageObject);
        if (!(activeBoard !== undefined || activeBoard !== null) || !configModeActive || !boards) throw new Error("Invalid object passed to parser function");

        let returnObject = {
            activeBoard,
            configModeActive,
            boards: []
        }

        for (let board of boards) {
            returnObject.boards.push(this.convertTabDataToObject(board));
        }

        return returnObject;
    }

    createEmptyState() {
        return {
            activeBoard: 0,
            configModeActive: true,
            boards: this.createDefaultDashboard()
        };
    }

    createNew(authObject) {
        return new TabObject("New Dashboard", new Date().toLocaleString(), "DP002E", authObject.username, [], []);
    }

    createDefaultDashboard() {
        return [
            new TabObject("Clarity Dash", new Date().toLocaleString(), "DP002E", "system", [
                new TabColumn("serials", "Serial Numbers", new Date().toLocaleString(), "=SERIALS()", true),
                // firmware & vp columns
                new TabColumn("count_fw", "Firmware Count", new Date().toLocaleString(), "=COUNT(FW(left))", false),
                new TabColumn("count_vp", "Voiceprompt Count", new Date().toLocaleString(), "=COUNT(VP())", false),

                // top 10 crashes columns v1.1.1
                new TabColumn("count_crash_left3", "Top 10 Crashes Left of v1.1.1", new Date().toLocaleString(), "=TOP10CRASHES(left, 1.1.1)", false),
                new TabColumn("count_crash_right3", "Top 10 Crashes Right of v1.1.1", new Date().toLocaleString(), "=TOP10CRASHES(right, 1.1.1)", false),

                // OTA Succes & Failed
                new TabColumn("avg_ota_succes", "Average Succesfull OTA Upgrades (Left & Right)", new Date().toLocaleString(), "=DEVIDE(ADD(STATS(LEFT_SUCCESSFUL_OTA_UPGRADES), STATS(RIGHT_SUCCESSFUL_OTA_UPGRADES)), 2)", false),
                new TabColumn("cnt_avg_ota_succes", "Count OTA Succes", new Date().toLocaleString(), "=COUNT(COLUMN(avg_ota_succes))", false),
                new TabColumn("avg_ota_fail", "Average OTA Fails", new Date().toLocaleString(), "=DEVIDE(ADD(STATS(LEFT_OTA_FAILS), STATS(RIGHT_OTA_FAILS)), 2)", false),
                new TabColumn("cnt_avg_ota_fail", "Count OTA Fails", new Date().toLocaleString(), "=COUNT(COLUMN(avg_ota_fail))", false),

                // OTA Cradle Succes & Failed
                new TabColumn("cradle_ota_succes", "Cradle OTA Succes", new Date().toLocaleString(), "=COUNT(STATS(CRADLE_SUCCESSFUL_OTA_UPGRADES))", false),
                new TabColumn("cradle_ota_fail", "Cradle OTA Fail", new Date().toLocaleString(), "=COUNT(STATS(CRADLE_OTA_UPGRADE_FAILS))", false),

                // Operating time
                new TabColumn("operating_time", "Operating Time", new Date().toLocaleString(), "=OPERATINGTIME()", false),
                new TabColumn("usage_time", "Usage Time", new Date().toLocaleString(), "=USAGETIME()", false)
            ], [
                // firmware & vp visuals
                new TabVisual("vis_fw", "Firmware Distribution", new Date().toLocaleString(), "count_fw", "pie"),
                new TabVisual("vis_pie_vp", "Voiceprompt Distribution", new Date().toLocaleString(), "count_vp", "bar"),

                new TabVisual("vis_operating_time", "Operating Time", new Date().toLocaleString(), "operating_time", "optime"),
                new TabVisual("vis_usage_time", "Usage Time", new Date().toLocaleString(), "usage_time", "usagetime"),
                // ota succes & fail visuals
                new TabVisual("vis_ota_succes", "Earbud OTA Succes (FW & VP)", new Date().toLocaleString(), "cnt_avg_ota_succes", "bar"),
                new TabVisual("vis_ota_fail", "Earbud OTA Fail (FW & VP)", new Date().toLocaleString(), "cnt_avg_ota_fail", "bar"),

                // cradle ota succes & fail visuals
                new TabVisual("vis_ota_succes_cradle", "Cradle OTA Succes (FW)", new Date().toLocaleString(), "cradle_ota_succes", "bar"),
                new TabVisual("vis_ota_fail_cradle", "Cradle OTA Fail (FW)", new Date().toLocaleString(), "cradle_ota_fail", "bar"),

                // top 10 crashes visuals, v1.0.9
                new TabVisual("vis_fatal_left3", "Top 10 Crashes Left of v1.1.1", new Date().toLocaleString(), "count_crash_left3", "top10"),
                new TabVisual("vis_fatal_right3", "Top 10 Crashes Right of v1.1.1", new Date().toLocaleString(), "count_crash_right3", "top10")
            ],
                new TabFilter("2210", null))
        ]
    }
}

export default DashboardObject;