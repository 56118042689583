import React, { useEffect } from 'react';
import { Grid } from 'gridjs-react';
import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';

const getGridItemsWrapper = (gridInstance) => {
    return gridInstance.config.container.firstChild.children[1];
}

const DataRender = ({ data }) => {
    const { left, right, cradle, mobile, logs } = data;
    const eventGridLeft = React.useRef();
    const eventGridRight = React.useRef();
    const eventGridCradle = React.useRef();

    let left_events;
    let right_events;
    let left_counters;
    let right_counters;
    let cradle_counters;
    let cradle_events;
    let mobile_data;
    let log_data;

    if (left) {
        left_events = left.events;
        if (left_events) {
            let newArray = [];
            for (let eventString of left_events) {
                newArray.push([eventString]);
            }
            left_events = newArray;
        }

        if (left.counters)
            left_counters = Object.entries(left.counters);
    }

    if (right) {
        right_events = right.events;
        if (right_events) {
            let newArray = [];
            for (let eventString of right_events) {
                newArray.push([eventString]);
            }
            right_events = newArray;
        }

        if (right.counters)
            right_counters = Object.entries(right.counters);
    }

    if (cradle) {
        cradle_events = cradle.events;
        if (cradle_events) {
            let newArray = [];
            for (let eventString of cradle_events) {
                newArray.push([eventString]);
            }

            cradle_events = newArray;
        }

        if (cradle.counters)
            cradle_counters = Object.entries(cradle.counters);
    }

    if (mobile) {
        let mobileArray = [];
        for (let mobileRecord of mobile) {
            let last_seen = new Date(mobileRecord.LAST_SEEN);
            mobileRecord.LAST_SEEN = `${last_seen.toLocaleTimeString()} ${last_seen.toLocaleDateString()}`;
            let recordArray = [mobileRecord.DEVICE, mobileRecord.OS, mobileRecord.OS_VERSION, mobileRecord.LAST_SEEN];

            mobileArray.push(recordArray);
        }

        mobile_data = mobileArray;
    }

    if (logs) {
        let logArray = [];
        for (let logRecord of logs) {
            let recordDate = new Date(logRecord.DATETIME);
            let recordDateString = `${recordDate.toLocaleTimeString()} ${recordDate.toLocaleDateString()}`;

            let recordArray = [logRecord.SERIAL, recordDateString, logRecord.TITLE, logRecord.MESSAGE];
            logArray.push(recordArray);
        }

        if (logArray.length !== 0)
            log_data = logArray;
    }

    useEffect(() => {
        if (left_events) {
            let instance = eventGridLeft.current?.getInstance();
            if (instance) {
                let gridWrapper = getGridItemsWrapper(instance);
                gridWrapper.scroll({
                    top: gridWrapper.scrollHeight,
                    left: 0,
                    behavior: 'instant'
                });
            }
        }

        if (right_events) {
            let instance = eventGridRight.current?.getInstance();
            if (instance) {
                let gridWrapper = getGridItemsWrapper(instance);
                gridWrapper.scroll({
                    top: gridWrapper.scrollHeight,
                    left: 0,
                    behavior: 'instant'
                });
            }
        }

        if (cradle_events) {
            let instance = eventGridCradle.current?.getInstance();
            if (instance) {
                let gridWrapper = getGridItemsWrapper(instance);
                gridWrapper.scroll({
                    top: gridWrapper.scrollHeight,
                    left: 0,
                    behavior: 'instant'
                })
            }
        }
    }, [data]);

    return (
        <div className='data-wrapper'>
            {<div className='part-wrapper options-section'>
                <h3 className='section-header mt-1'>Events</h3>
                <Row align="stretch" className="grid-wrapper">
                    {left_events && <Col span={6} xs={12} md={12} lg={6} xl={6} className="gridjs-column-wrapper">
                        <h4 className='section-header mt-1'>Left</h4>
                        {left_events && <Grid
                            data={left_events}
                            columns={['Event String']}
                            search={true}
                            height='500px'
                            fixedHeader={true}
                            ref={eventGridLeft}
                        />}
                    </Col>}
                    {right_events && <Col span={6} xs={12} md={12} lg={6} xl={6} className="gridjs-column-wrapper">
                        <h4 className='section-header mt-1'>Right</h4>
                        {right_events && <Grid
                            data={right_events}
                            columns={['Event String']}
                            search={true}
                            height='500px'
                            fixedHeader={true}
                            ref={eventGridLeft}
                        />}
                    </Col>}
                </Row>
                {!left_events && !right_events &&
                    <Row align="stretch" justify="center">
                        <Col span={4} className="no-options">
                            No Events were selected to be retrieved
                        </Col>
                    </Row>
                }
            </div>}
            {<div className='part-wrapper options-section'>
                <h3 className='section-header mt-1'>Counters</h3>
                <Row align="stretch" className="grid-wrapper">
                    {left_counters && <Col span={6} xs={12} md={12} lg={6} xl={6} className="gridjs-column-wrapper">
                        <h4 className='section-header mt-1'>Left</h4>
                        {left_counters && <Grid
                            data={left_counters}
                            columns={['Counter Name', 'Value']}
                            search={true}
                            height='500px'
                            fixedHeader={true}
                        />}
                    </Col>}
                    {right_counters && <Col span={6} xs={12} md={12} lg={6} xl={6} className="gridjs-column-wrapper">
                        <h4 className='section-header mt-1'>Right</h4>
                        {right_counters && <Grid
                            data={right_counters}
                            columns={['Counter Name', 'Value']}
                            search={true}
                            height='500px'
                            fixedHeader={true}
                        />}
                    </Col>}
                </Row>
                {!left_counters && !right_counters &&
                    <Row align="stretch" justify="center">
                        <Col span={4} className="no-options">
                            No Counters were selected to be retrieved
                        </Col>
                    </Row>
                }
            </div>}
            {cradle && <div className='part-wrapper options-section'>
                <h3 className='section-header mt-1'>Cradle</h3>
                <Row align="stretch" className="main-row">
                    {cradle_counters && <Col span={6} xs={12} md={12} lg={6} xl={6} className="gridjs-column-wrapper">
                        <h4 className='section-header mt-1'>Counters</h4>
                        {cradle_counters && <Grid
                            data={cradle_counters}
                            columns={['Counter Name', 'Value']}
                            search={true}
                            height='500px'
                            fixedHeader={true}
                        />}
                    </Col>}
                    {cradle_events && <Col span={6} xs={12} md={12} lg={6} xl={6} className="gridjs-column-wrapper">
                        <h4 className='section-header mt-1'>Events</h4>
                        {cradle_events && <Grid
                            data={cradle_events}
                            columns={['Event String']}
                            search={true}
                            height='500px'
                            fixedHeader={true}
                            ref={eventGridCradle}
                        />}
                    </Col>}
                </Row>
                {!cradle_counters && !cradle_events &&
                    <Row align="stretch" justify="center">
                        <Col span={4} className="no-options">
                            Cradle was selected, but no counters were retrieved.
                        </Col>
                    </Row>
                }
            </div>}
            {mobile && <div className='part-wrapper options-section'>
                <h3 className='section-header mt-1'>Mobile</h3>
                <Row align="stretch" className="main-row">
                    {mobile_data && <Col span={8}>
                        {mobile_data && <Grid
                            data={mobile_data}
                            columns={['Device Name', "Operating System", "OS Version", "Last seen"]}
                            search={true}
                            height='200px'
                            fixedHeader={true}
                        />}
                    </Col>}
                </Row>
            </div>}
            {logs && <div className='part-wrapper options-section'>
                <h3 className='section-header mt-1'>Occurance Logs</h3>
                <Row align="stretch" className="main-row">
                    {log_data && <Col span={12}>
                        {log_data && <Grid
                            data={log_data}
                            columns={['Serial', "Timestamp", "Title", "Message"]}
                            search={true}
                            height='500px'
                            fixedHeader={true}
                        />}
                    </Col>}
                </Row>
                {!log_data && !left_events &&
                    <Row align="stretch" justify="center">
                        <Col span={4} className="no-options">
                            No logs were found for this product
                        </Col>
                    </Row>
                }
            </div>}
        </div>
    )
};

export default DataRender;
