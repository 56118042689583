
import React, {  } from "react";

export const SignOut = props => {
    setTimeout(() => {
        localStorage.removeItem("authentication");
        window.location.replace("/");
    }, 1000);

    return (
        <div className="loading-box">
            Please wait while you are signed out...
        </div>
    )
}