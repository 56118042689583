import './styles/Register.css'
import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import WebHelper from '../api/WebHelper';
import { useParams } from "react-router-dom";


const InputField = React.forwardRef(({ label, type, disabled = false, defaultValue = '' }, ref) => {
    return (
        <div>
            <label className='login-label' >{label}</label>
            <input ref={ref} type={type} className='login-input' disabled={disabled} defaultValue={defaultValue} />
        </div>
    );
});

const RegistrationForm = ({ onAccountCreated, regData }) => {
    const fullNameRef = React.useRef();
    const emailRef = React.useRef();
    const passwordRef = React.useRef();
    const passwordConfirmRef = React.useRef();

    const navigate = useNavigate();

    const [fFullNameError, setFFullNameError] = useState("");
    const [fPasswordError, setFPasswordError] = useState("");
    const [fPasswordConfirmError, setFPasswordConfirmError] = useState("");

    const onCreateSuccess = data => {
        console.log("account create success: ", data);
        setFPasswordConfirmError("Account created. Use your email address to sign in. Redirecting in 5 seconds...");
        setTimeout(() => {
            navigate("/");
        }, 5000);
    }

    const onCreateFail = error => {
        console.log("account create error: ", error);
        setFPasswordConfirmError("Error creating account, contact developer.");
    }

    const handleSubmit = e => {
        e.preventDefault();

        const data = {
            full_name: fullNameRef.current.value,
            email: emailRef.current.value,
            password: passwordRef.current.value,
            passwordConfirm: passwordConfirmRef.current.value
        };

        if (data.full_name === "") {
            setFFullNameError("Please enter your full name");
        }
        else {
            setFFullNameError("");
        }

        if (data.password === "") {
            setFPasswordError("Please enter a password");
        }
        else {
            //todo: check password length and if its a strong password
            setFPasswordError("");

            if (data.passwordConfirm === "") {
                setFPasswordConfirmError("Please enter a password");
            }
            else {
                if (data.password !== data.passwordConfirm) {
                    setFPasswordConfirmError("Passwords do not match");
                }
                else {
                    setFPasswordConfirmError("");
                }
            }
        }
        //todo: start the registration. There is a possibility that the username is already taken, make sure to render this error back to the user.
        WebHelper.completeRegistration(regData.token, JSON.stringify(data), onCreateSuccess, onCreateFail);
    };

    return (
        <form className='overview-box graph-screen' onSubmit={handleSubmit} >
            <div className='black-text'>
                <p>Your account has been partially created. All you have to do is think of a password.</p>
            </div>
            <InputField ref={emailRef} label="Email" type="email" disabled={true} defaultValue={regData.email} />
            <InputField ref={fullNameRef} label="Full Name" type="text" defaultValue={regData.name} />
            {fFullNameError &&
                <div className='login-error mt-0'>
                    {fFullNameError}
                </div>
            }
            <InputField ref={passwordRef} label="Password" type="password" />
            {fPasswordError &&
                <div className='login-error mt-0'>
                    {fPasswordError}
                </div>
            }
            <InputField ref={passwordConfirmRef} label="Confirm Password" type="password" />
            {fPasswordConfirmError &&
                <div className='login-error mt-0'>
                    {fPasswordConfirmError}
                </div>
            }
            <div>
                <button className='login-button' type="submit" disabled={false}>{false ? "Sending request" : "Complete my Account"}</button>
            </div>
        </form>
    );
};

const Register = (props) => {
    const { reg_token } = useParams();
    const [allow, setAllow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [regData, setRegData] = useState(null);

    // todo: if signed in say that account completion can only happen if signed out.

    const onSuccess = useCallback((data) => {
        setLoading(false);
        if (data.name && data.email) {
            setRegData(data);
            setAllow(true);
        }
    }, []);

    const onError = useCallback((error) => {
        setLoading(false);
    }, []);

    useEffect(() => {
        WebHelper.getRegisterParams(reg_token, onSuccess, onError);
    }, []);

    if (loading) {
        return (
            <Row align="stretch" className="main-row">
                <Col span={2}
                    xs={12} md={4} lg={3} xl={2} className="side-bar">
                    <div className="filter-bar">
                        <h3 className='mt-0'>Registration</h3>
                        <div>Complete your account</div>
                    </div>
                </Col>
                <Col span={10}
                    xs={12} md={8} lg={9} xl={10}>
                    <div className='graph-screen-wrapper'>
                        <div className='overview-box graph-screen'>
                            <p className='black-text'>Checking your request with the server</p>
                            <p className='blue'>Please wait...</p>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    if (!allow) {
        return (
            <Row align="stretch" className="main-row">
                <Col span={2}
                    xs={12} md={4} lg={3} xl={2} className="side-bar">
                    <div className="filter-bar">
                        <h3 className='mt-0'>Registration</h3>
                        <div>Complete your account</div>
                    </div>
                </Col>
                <Col span={10}
                    xs={12} md={8} lg={9} xl={10}>
                    <div className='graph-screen-wrapper'>
                        <div className='overview-box graph-screen'>
                            <p className='black-text'>This link is invalid or expired.</p>
                            <p className='blue'>Ask an administrator to send you a new link.</p>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
    else {
        return (
            <Row align="stretch" className="main-row">
                <Col span={2}
                    xs={12} md={4} lg={3} xl={2} className="side-bar">
                    <div className="filter-bar">
                        <h3 className='mt-0'>Registration</h3>
                        <div>Complete your account</div>
                    </div>
                </Col>
                <Col span={10}
                    xs={12} md={8} lg={9} xl={10}>
                    <div className='graph-screen-wrapper'>
                        <RegistrationForm regData={regData} />
                    </div>
                </Col>
            </Row>
        );
    }

}

export default Register;