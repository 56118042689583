import React, { } from "react";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
    return (
        <div className='graph-screen-wrapper'>
            <div className="graph-screen">
                This area has not yet been built. <br></br> To reset your password, logout, and then click the forgot password button.
            </div>
        </div>
    );
}

export default ForgotPassword;