class RoleParser {
    static create(authObject) {
        let p = new RoleParser();
        p.#parse(authObject);
        return p;
    }

    constructor() {
        this.roles = [];
    }

    #parse(authObject) {
        const { username, roles } = authObject;
        console.log(username, roles);
        this.roles = roles;
    }


    #findRoles(roleName) {

        let returnRoles = [];

        for (let role of this.roles) {
            if (role === roleName) {
                returnRoles.push(role);
            }
        }

        return returnRoles;
    }

    contains(roleName) {
        let roleCount = this.#findRoles(roleName);

        if (roleCount.length === 0) {
            return false;
        }

        return true;
    }
}

export default RoleParser;