import React, { useEffect, useState, useContext, useRef } from "react";
import { MContext } from "../session/SessionContext";
import { Link, Outlet, useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { getTabData } from "../api/WebHelper";
import { Grid } from 'gridjs-react';
import Visualizer from "../visuals/VisualRenderer";
import useKey from "../session/UseKey";

function convertDeviceDataToText(param) {
    if (Array.isArray(param)) {
        return convertArrayToText(param);
    }
    else {
        return convertKeyValueToText(param);
    }
}

function convertArrayToText(param) {
    let compiledDataString = "";
    if (param.length === 1) {
        let subRow = param[0];
        return convertDeviceDataToText(subRow);
    }
    else {
        for (let dR of param) {
            compiledDataString += (convertKeyValueToText(dR) + "\n\r");
        }
    }

    return compiledDataString;
}

function convertKeyValueToText(param) {
    //get all the object entries and create a nicely formatted line for it.
    if (param === null) {
        return "null";
    }

    if (String(param) !== "[object Object]") {
        return String(param);
    }

    let compiledDataString = "";
    let paramEntries = Object.entries(param);
    for (let dR of paramEntries) {
        //only allow arrays to be 2 levels nested in the record
        compiledDataString += (dR[0] + ": " + convertKeyValueToText(dR[1]) + "\n\r");
    }

    return compiledDataString;
}

function convertCrashRecordToText(dR) {
    const { file, event, line } = dR;
    if (file && event && line) {
        let crashLine = `${event}\r\n\tfile: ${file}\r\n\tline: ${line}`;
        return (crashLine + "\n\n");
    }
    else {
        return convertKeyValueToText(dR);
    }
}

const DashDataRender = (props) => {
    const { dashData, boardColumns } = props;
    const [gridData, setGridData] = useState(null);
    const gridRef = useRef();

    useEffect(() => {
        if (dashData !== null) {
            let gridColumns = [];
            let gridDataPreSorted = [];
            for (let colId in boardColumns) {
                const col = boardColumns[colId];
                if (col.visible) {
                    gridColumns.push(col.name);
                }
            }

            let dashDataEntries = Object.entries(dashData);
            let maxLength = 0;
            for (let entry of dashDataEntries) {
                let len = dashData[entry[0]].length;
                if (len > maxLength) {
                    maxLength = len;
                }
            }

            for (let rowId = 0; rowId < maxLength; rowId++) {
                let rowData = [];
                for (let colId in boardColumns) {
                    const col = boardColumns[colId];
                    if (col.visible) {
                        let rowAndColumnData = dashData[col.id][rowId];
                        if (rowAndColumnData === undefined) {
                            //data didnt exist: 
                            rowData.push("undefined");
                        }
                        else {
                            rowData.push(convertDeviceDataToText(rowAndColumnData));
                        }
                    }
                }

                gridDataPreSorted.push(rowData);
            }
            setGridData({ data: gridDataPreSorted, columns: gridColumns })

        }
        else {
            setGridData(null);
        }
    }, [dashData, boardColumns]);

    if (gridData != null) {
        return (
            <Grid
                data={gridData.data}
                columns={gridData.columns}
                search={true}
                fixedHeader={true}
                height='500px'
                sort={true}
                pagination={{
                    limit: 10,
                    summary: true
                }}
                style={
                    {
                        td: {
                            whiteSpace: "pre-wrap"
                        }
                    }
                }
                ref={gridRef}
            />
        );
    }
    else {
        return (
            <div>Loading...</div>
        );
    }
};

const Dash = (props) => {
    const { board, authObject } = props;
    const [downloadedApiData, setDownloadedApiData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [boardColumns, setBoardColumns] = useState(null);
    const [boardVisuals, setBoardVisuals] = useState(null);
    const [tableVisible, setTableVisible] = useState(false);

    const onDownloadSuccess = (response) => {
        setDownloadedApiData(response);
        setBoardColumns(board.columns);
        setBoardVisuals(board.visuals);
        setErrorMessage(null);
    };

    const onDownloadError = (error) => {
        console.error("ERROR", error);

        setErrorMessage(error);

        //resetDashData();
    }

    const resetDashData = () => {
        setDownloadedApiData(null);
        setBoardColumns(null);
        setBoardVisuals(null);
    }

    const calcTableVisible = () => {
        let vis = false;
        for (let col of board.columns) {
            if (col.visible) {
                vis = true;
            }
        }

        setTableVisible(vis);
    };

    useEffect(() => {
        resetDashData();
        calcTableVisible();
        return () => {
            resetDashData();
        }
    }, [board]);

    useEffect(() => {
        if (downloadedApiData === null) {
            getTabData(authObject.access_token, JSON.stringify(board.exportAsObject()), onDownloadSuccess, onDownloadError);
        }
    }, [downloadedApiData]);

    return (
        <div>
            <div className="chart-screen-wrapper">
                {(downloadedApiData !== null && boardVisuals !== null && boardColumns !== null) ?
                    <Visualizer dashboardData={downloadedApiData} boardVisuals={boardVisuals} boardColumns={boardColumns} />
                    :
                    <div className="graph-screen-wrapper">
                        <div className="graph-screen">
                            <h3 className="board-title">Loading Visuals</h3>
                        </div>
                    </div>
                }
            </div>
            {tableVisible && <div className="graph-screen-wrapper">
                <div className="graph-screen">
                    <h3 className="board-title">{board.title}</h3>
                    {
                        (downloadedApiData !== null && boardColumns !== null && errorMessage === null) ? <DashDataRender dashData={downloadedApiData} boardColumns={boardColumns} /> :
                            (errorMessage !== null) ?
                                <div>
                                    <h3 className="board-title">{errorMessage.message}</h3>
                                </div>
                                :
                                <div>No Data downloaded yet</div>
                    }
                </div>
            </div>}
        </div>
    )
};

export const StaticDash = (props) => {
    const { board, authObject } = props;
    const [downloadedApiData, setDownloadedApiData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [boardColumns, setBoardColumns] = useState(null);
    const [boardVisuals, setBoardVisuals] = useState(null);
    const [tableVisible, setTableVisible] = useState(false);

    const onDownloadSuccess = (response) => {
        setDownloadedApiData(response);
        setBoardColumns(board.columns);
        setBoardVisuals(board.visuals);
        setErrorMessage(null);
    };

    const onDownloadError = (error) => {
        console.error("ERROR", error);

        setErrorMessage(error);

        //resetDashData();
    }

    const resetDashData = () => {
        setDownloadedApiData(null);
        setBoardColumns(null);
        setBoardVisuals(null);
    }

    const calcTableVisible = () => {
        let vis = false;
        for (let col of board.columns) {
            if (col.visible) {
                vis = true;
            }
        }

        setTableVisible(vis);
    };

    useEffect(() => {
        resetDashData();
        calcTableVisible();
        return () => {
            resetDashData();
        }
    }, [board]);

    useEffect(() => {
        if (downloadedApiData === null) {
            getTabData(authObject.access_token, JSON.stringify(board.exportAsObject()), onDownloadSuccess, onDownloadError);
        }
    }, [downloadedApiData]);

    return (
        <div>
            <div className="graph-screen-wrapper">
                <div className="graph-screen">
                    <h3 className="board-title">Default Clarity Dashboard</h3>
                    <h4 className="board-title"></h4>
                    <p className="black-text">You can access your custom dashboards through the left panel</p>
                </div>
            </div>
            <div className="chart-screen-wrapper">
                {(downloadedApiData !== null && boardVisuals !== null && boardColumns !== null) ?
                    <Visualizer dashboardData={downloadedApiData} boardVisuals={boardVisuals} boardColumns={boardColumns} />
                    :
                    <div className="graph-screen-wrapper">
                        <div className="graph-screen">
                            <h3 className="board-title">Loading Visuals</h3>
                        </div>
                    </div>
                }
            </div>

        </div>
    )
};

const DashRender = () => {
    let dashboardStorage = useOutletContext();
    let contextValue = useContext(MContext);
    let navigate = useNavigate();
    useKey('ctrlk', (e) => {
        e.preventDefault();
        navigate("/dash/config");
    });

    const authObject = contextValue.state.authentication;
    if (dashboardStorage.activeBoard !== -1) {
        const board = dashboardStorage.boards[dashboardStorage.activeBoard];
        console.log(dashboardStorage.boards[dashboardStorage.activeBoard]);
        return (
            <Dash board={board} authObject={authObject} />
        )
    }
    else {
        return (
            <div className="graph-screen-wrapper">
                <div className="graph-screen black-text">No dashboard activated to render</div>
            </div>
        )
    }
}

export default DashRender;