class TabVisual {
    constructor(id, name, created, target, renderType) {
        this.id = id;
        this.name = name;
        this.created = created;
        this.target = target;
        this.renderType = renderType;
    }

    exportAsObject() {
        return {
            vis_id: this.id,
            vis_name: this.name,
            vis_created: this.created,
            vis_target: this.target,
            vis_render_type: this.renderType
        };
    }
}

export default TabVisual;