import './styles/AuthScreen.css'
import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';
import React, { useEffect, useState, useContext } from "react";
import { Link, Outlet, useNavigate, useLocation, useOutletContext } from "react-router-dom";
import authManager from '../security/authManager';
import queryString from 'query-string';
import { MContext } from '../session/SessionContext';
import DashboardObject from '../dashboard/DashboardObject';

const InputField = React.forwardRef(({ label, type }, ref) => {
    return (
        <div>
            <label className='login-label' >{label}</label>
            <input ref={ref} type={type} className='login-input' />
        </div>
    );
});

const LoginForm = ({ onProfileRetrieved }) => {
    const contextValue = useContext(MContext);
    const signInCounts = contextValue.state.signInCounts;
    const usernameRef = React.useRef();
    const passwordRef = React.useRef();
    const [loggingIn, setLoggingIn] = useState(false);
    const [signInError, setSignInError] = useState("");

    useEffect(() => {
        if (signInCounts.count === undefined || signInCounts.count === null) {
            let countObject = JSON.parse(localStorage.getItem("signInCounts"));
            if (countObject) {
                contextValue.setSignInCounts(countObject);
            }
        }
    }, [contextValue, signInCounts]); //

    const onGetProfileError = error => {
        setSignInError("Could not get user profile.");
    }

    const onSignInSuccess = response => {
        contextValue.setSignInCounts({
            count: 0,
            date: new Date().getTime()
        });
        authManager.getProfile(response.access_token, onProfileRetrieved, onGetProfileError);
    };

    const onSignInError = error => {
        console.error("Login Error: ", error);
        setSignInError(error.message);
        setLoggingIn(false);
    };

    const signInCounterHitMax = (countObj) => {
        const currStamp = new Date().getTime();
        let MinutesPassedSinceLastAttempt = Math.floor((currStamp - countObj.date) / 1000 / 60);
        let SecondsPassedSinceLastAttempt = Math.round((currStamp - countObj.date) / 1000) - (60 * MinutesPassedSinceLastAttempt);


        if (MinutesPassedSinceLastAttempt >= 5) {
            contextValue.setSignInCounts({
                count: 1,
                date: 0
            });

            return true;
        }

        const minutes = 4 - MinutesPassedSinceLastAttempt;
        const seconds = 59 - SecondsPassedSinceLastAttempt;

        const paddedSeconds = seconds < 10 ? "0" + seconds : "" + seconds;

        setSignInError(`Too many attempts, try again in ${minutes}:${paddedSeconds} minutes`);

        return false;
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();

    };

    const handleSubmit = e => {
        e.preventDefault();

        if (loggingIn) return;

        let numb = parseInt(signInCounts.count ? signInCounts.count : 0, 10);
        if (numb >= 5) {
            const allowCont = signInCounterHitMax(signInCounts);

            if (!allowCont)
                return;
        }
        else {
            contextValue.setSignInCounts({
                count: numb + 1,
                date: new Date().getTime()
            });
        }

        const data = {
            username: usernameRef.current.value,
            password: passwordRef.current.value
        };

        if (data.username === "" || data.password === "") {
            let errorWeight = 0;
            if (data.username !== "") errorWeight = 1;
            if (data.password !== "") errorWeight = 2;

            let messageArray = {
                0: "Please fill in your username and your password",
                1: "Please fill in your password",
                2: "Please fill in your username"
            }

            setSignInError(messageArray[errorWeight]);
            return;
        }

        setLoggingIn(true);
        authManager.login(data.username, data.password, onSignInSuccess, onSignInError);
    };

    return (
        <div>
            <form className='login-box' onSubmit={handleSubmit} >
                <InputField ref={usernameRef} label="Email:" type="email" />
                <InputField ref={passwordRef} label="Password:" type="password" />
                <div>
                    <button className='login-button' type="submit" disabled={loggingIn}>{loggingIn ? "Sending request" : "Login"}</button>
                </div>
                {signInError && <div className='login-error'>
                    {signInError}
                </div>}
            </form>

            <div className='forgot-password-box'>
                <Link to="/account/forgot" className='forgot-password-button'>Forgot Password?</Link>
            </div>

        </div>
    );
};

const ProfileView = () => {
    const outletContext = useOutletContext();
    const name = String(outletContext.name);
    const username = String(outletContext.username);
    const email = String(outletContext.email)

    return (
        <div className='overview-box'>
            <div>
                <label className='login-label' >Username</label>
                <input type="text" className='login-input' value={username} disabled />
            </div>
            <div>
                <label className='login-label' >Full Name</label>
                <input type="text" className='login-input' value={name} disabled />
            </div>
            <div>
                <label className='login-label' >Email</label>
                <input type="text" className='login-input' value={email} disabled />
            </div>
            <div>
                <Link to="/authenticate/edit" className='login-button'>Change Profile</Link>
            </div>
        </div>
    )
};

const ProfileEdit = () => {
    const outletContext = useOutletContext();
    const name = String(outletContext.name);
    const username = String(outletContext.username);
    const email = String(outletContext.email)

    let navigate = new useNavigate();

    const usernameRef = React.useRef();
    const nameRef = React.useRef();
    const emailRef = React.useRef();

    const handleSubmit = e => {
        e.preventDefault();
        const data = {
            username: usernameRef.current.value,
            name: nameRef.current.value,
            email: emailRef.current.value
        };
        console.log(data);
        navigate("/authenticate");
    };

    return (
        <div className='overview-box'>
            <form onSubmit={handleSubmit} >
                <div>
                    <label className='login-label' >Username</label>
                    <input ref={usernameRef} type="text" className='login-input' defaultValue={username} />
                </div>
                <div>
                    <label className='login-label' >Full Name</label>
                    <input ref={nameRef} type="text" className='login-input' defaultValue={name} />
                </div>
                <div>
                    <label className='login-label' >Email</label>
                    <input ref={emailRef} type="text" className='login-input' defaultValue={email} />
                </div>
                <div>
                    <button className='login-button' type="submit">Save</button>
                </div>
            </form>
        </div>
    )
};

const AuthScreen = (props) => {
    const [allow, setAllow] = useState(false);
    const contextValue = useContext(MContext);
    const navigate = useNavigate();
    const authObject = contextValue.state.authentication;
    const location = useLocation();
    const parsed = queryString.parse(location.search);

    useEffect(() => {
        if (!authObject.username) {
            console.log("Not signed in.");
        }
        else {
            if (parsed.returnUrl) {
                navigate(parsed.returnUrl);
            }
            else {
                setAllow(true);
            }
        }
    }, [authObject, parsed.returnUrl, navigate]);

    const onDashboardReset = () => {
        console.log("resetting dashboard data to default.");
        contextValue.setDashboardStorage(new DashboardObject().createEmptyState());
    }

    const onRawStorageReset = () => {
        console.log("resetting raw product search data");
    }

    const onGetProfileSuccess = response => {
        console.log(response);
        contextValue.setAuthentication(response);
    }

    if (!allow) {
        return (
            <Row align="stretch" className="main-row">
                <Col span={2}
                    xs={12} md={4} lg={3} xl={2} className="side-bar">
                    <div className="filter-bar">
                        <h3 className='mt-0'>Not signed in</h3>
                        <div>
                            Please sign-in to continue!
                        </div>
                    </div>
                </Col>
                <Col span={10}
                    xs={12} md={8} lg={9} xl={10}>
                    <div className='login-box-wrapper'>
                        <LoginForm onProfileRetrieved={onGetProfileSuccess} />
                    </div>
                </Col>
            </Row>
        );
    }
    else {
        const name = String(authObject.name);
        return (
            <Row align="stretch" className="main-row">
                <Col span={2}
                    xs={12} sm={12} md={12} lg={2} xl={2} className="side-bar">
                    <div className="filter-bar">
                        <h3 className='mt-0'>Hello, <br></br> {name}</h3>
                        <div>
                            <div className='dashboard-button' onClick={onDashboardReset}>
                                Reset dashboards to default
                            </div>
                        </div>
                        <div>
                            <div className='dashboard-button' onClick={onRawStorageReset}>
                                Reset product search settings
                            </div>
                        </div>
                        <div>
                            <Link to="/authenticate/change_psswd" className='no-link'>
                                <div className='dashboard-button'>
                                    Change Password
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to="/signout" className='no-link'>
                                <div className='dashboard-button'>
                                    Log out
                                </div>
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col span={10}
                    xs={12} sm={12} md={12} lg={10} xl={10}>
                    <div className='login-box-wrapper'>
                        <Outlet context={authObject} />
                    </div>
                </Col>
            </Row>
        );
    }
}

export { AuthScreen, ProfileView, ProfileEdit };