class TabObject {
    constructor(title, created, product, creator, columns, visuals, filter) {
        this.title = title;
        this.created = created;
        this.product = product;
        this.creator = creator;
        this.columns = columns;
        this.visuals = visuals;
        this.filter = filter;
    }

    exportAsObject() {
        let exportObject = {
            tab_title: this.title,
            tab_created: this.created,
            tab_product: this.product,
            tab_creator: this.creator,
            tab_col: [],
            col_visuals: [],
            tab_filter: this.filter
        };

        for (let colId in this.columns) {
            let column = this.columns[colId];
            exportObject.tab_col.push(column.exportAsObject());
        }

        for (let visId in this.visuals) {
            let visual = this.visuals[visId];
            exportObject.col_visuals.push(visual.exportAsObject());
        }

        return exportObject;
    }
}

export default TabObject;