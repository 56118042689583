import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import DashboardObject from '../dashboard/DashboardObject';
import { MContext } from "../session/SessionContext";
import { StaticDash } from "./DashRender";

const ModePicker = () => {
    let contextValue = useContext(MContext);
    const authObject = contextValue.state.authentication;
    const dashboardStorage = contextValue.state.dashboardStorage;
    const board = new DashboardObject().createDefaultDashboard()[0];

    useEffect(() => {
        // rendering the default board, also set the current board to -1
        dashboardStorage.activeBoard = -1;
        contextValue.setDashboardStorage(dashboardStorage);
    }, []);

    return (
        <div>
            {/* <div>Pick a mode</div>
            <div className='dashboard-inline'>
                <Link to="/dash/render">
                    <div className='dashboard-button'>
                        Render Mode
                    </div>
                </Link>
                <Link to="/dash/config">
                    <div className='dashboard-button'>
                        Configuration Mode
                    </div>
                </Link>
            </div> */}

            <StaticDash board={board} authObject={authObject} />
        </div>
    );
}

export default ModePicker;