function createPostRequest(url, body, authKey = null) {
    const headers = { 'Content-Type': 'application/json' };
    if (authKey) {
        headers["x-access-token"] = authKey;
    }
    return new Request(url, {
        method: 'POST',
        body: body,
        headers: new Headers(headers)
    });
}

function createGetRequest(url, authKey = null) {
    const headers = {};

    if (authKey) {
        headers["x-access-token"] = authKey;
    }

    return new Request(url, {
        method: 'GET',
        headers: new Headers(headers),
    });
}

function doRequest(request, onSuccess, onError) {
    fetch(request).then(response => {
        if (response.status < 200 || response.status >= 300) {
            let errorMessage;
            if (response.status === 401) {
                errorMessage = "Credentials Expired";
            } else if (response.status === 403) {
                errorMessage = "Not allowed to do this";
            }
            else if (response.status === 400) {
                errorMessage = "Missing data";
                response.json().then(r => onError(r));
                return null;
            }
            else if (response.status === 429) {
                errorMessage = "Too many requests";
            }
            else {
                errorMessage = "No Specific error provided";
            }
            response.json().then(r => onError(r));
            //console.log("response",response.body)
           // throw new Error(response.statusText + " " + errorMessage);

           return null;
        }

        return response.json();
    }).then(data => {
        return onSuccess(data);
    }).catch(error => {
        return onError(error);
    });
}

module.exports = {
    getRegisterParams: function (reg_token, onSuccess, onError) {
        const request = createGetRequest(process.env.REACT_APP_API_BASE_URL + "/authorize/register/" + reg_token);

        fetch(request).then(reg_response => {
            if (reg_response.status < 200 || reg_response.status >= 300) {
                let errorMessage;
                if (reg_response.status === 401) {
                    errorMessage = "Credentials Expired";
                } else if (reg_response.status === 403) {
                    errorMessage = "Not allowed to do this";
                }
                else {
                    errorMessage = "No Specific error provided";
                }

                throw new Error(reg_response.statusText + " " + errorMessage);
            }

            return reg_response.json();
        }).then(data => {
            return onSuccess(data);
        }).catch(error => {
            return onError(error);
        });
    },
    completeRegistration: function (reg_token, dataObject, onSuccess, onError) {
        const request = createPostRequest(process.env.REACT_APP_API_BASE_URL + "/authorize/register/" + reg_token, dataObject);

        doRequest(request, onSuccess, onError);
    },
    getReadoutDataBySerial: function (auth_token, bodyObject, onSuccess, onError) {
        const request = createPostRequest(process.env.REACT_APP_API_BASE_URL + "/retrieve/product_readout", bodyObject, auth_token);

        doRequest(request, onSuccess, onError);
    },
    getTabData: function (auth_token, bodyObject, onSuccess, onError) {
        const request = createPostRequest(process.env.REACT_APP_API_BASE_URL + "/retrieve/tab_data", bodyObject, auth_token);

        doRequest(request, onSuccess, onError);
    },
    getSerialSearch: function (auth_token, bodyObject, onSuccess, onError) {
        const request = createPostRequest(process.env.REACT_APP_API_BASE_URL + "/retrieve/search", bodyObject, auth_token);

        doRequest(request, onSuccess, onError);
    },
    getFrequency(auth_token, bodyObject, onSuccess, onError) {
        const request = createPostRequest(process.env.REACT_APP_API_BASE_URL + "/retrieve/frequency", bodyObject, auth_token);

        doRequest(request, onSuccess, onError);
    },
    getAllUsers: function (auth_token, onSuccess, onError) {
        const request = createGetRequest(process.env.REACT_APP_API_BASE_URL + "/authorize/admin_all_users", auth_token);

        doRequest(request, onSuccess, onError);
    },
    deleteUser: function (auth_token, username, onSuccess, onError) {
        const request = createPostRequest(process.env.REACT_APP_API_BASE_URL + "/authorize/admin_user_delete", JSON.stringify({ username }), auth_token);

        doRequest(request, onSuccess, onError);
    },
    updateUser: function (auth_token, userObject, onSuccess, onError) {
        const request = createPostRequest(process.env.REACT_APP_API_BASE_URL + "/authorize/admin_user_update", userObject, auth_token);

        doRequest(request, onSuccess, onError);
    },
    resetUserPsswd: function (auth_token, username, onSuccess, onError) {
        const request = createPostRequest(process.env.REACT_APP_API_BASE_URL + "/authorize/admin_user_passwd", JSON.stringify({ username }), auth_token);

        doRequest(request, onSuccess, onError);
    },
    getResetPasswordParams: function (token, onSuccess, onError) {
        const request = createGetRequest(process.env.REACT_APP_API_BASE_URL + "/authorize/reset_psswd/" + token);

        doRequest(request, onSuccess, onError);
    },
    completePasswordReset: function (token, dataObject, onSuccess, onError) {
        const request = createPostRequest(process.env.REACT_APP_API_BASE_URL + "/authorize/reset_psswd/" + token, dataObject);

        doRequest(request, onSuccess, onError);
    },
    registerNewUser: function (auth_token, bodyObject, onSuccess, onError) {
        const request = createPostRequest(process.env.REACT_APP_API_BASE_URL + "/authorize/register", bodyObject, auth_token);

        doRequest(request, onSuccess, onError);
    },
    requestPasswordLink: function (email, onSuccess, onError) {
        const request = createPostRequest(process.env.REACT_APP_API_BASE_URL + "/authorize/request_password_reset", JSON.stringify({username: email}), null);

        doRequest(request, onSuccess, onError);
    }
}