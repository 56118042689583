function createPostRequest(url, body) {
    return new Request(url, {
        method: 'POST',
        body: body,
        headers: new Headers({ 'Content-Type': 'application/json' }),
    });
}

function createGetRequest(url, authKey) {
    const headers = {};


    if (authKey) {
        headers["x-access-token"] = authKey;
    }

    return new Request(url, {
        method: 'GET',
        headers: new Headers(headers),
    });
}

module.exports = {
    login: function (username, password, onSuccess, onError) {
        const request = createPostRequest(process.env.REACT_APP_API_LOGIN_PATH, JSON.stringify({ username, password }));

        fetch(request).then(login_response => {
            if (login_response.status < 200 || login_response.status >= 300) {
                let errorMessage;
                if (login_response.status === 401) {
                    errorMessage = "Bad credentials";
                } else if (login_response.status === 403) {
                    errorMessage = "Account disabled";
                }
                else {
                    errorMessage = "No Specific error provided";
                }

                throw new Error(login_response.statusText + " " + errorMessage);
            }

            return login_response.json();
        }).then(data => {
            return onSuccess(data);
        }).catch(error => {
            return onError(error);
        });
    },
    getProfile: function (authKey, onSuccess, onError) {
        const request = createGetRequest(process.env.REACT_APP_API_PROFILE_PATH, authKey);

        fetch(request).then(profile_response => {
            if (profile_response.status < 200 || profile_response.status >= 300) {
                let errorMessage;
                if (profile_response.status === 401) {
                    errorMessage = "Credentials Expired";
                } else if (profile_response.status === 403) {
                    errorMessage = "Account disabled";
                }
                else {
                    errorMessage = "No Specific error provided";
                }

                throw new Error(profile_response.statusText + ": " + errorMessage);
            }
            return profile_response.json();
        }).then(data => {
            data["access_token"] = authKey;
            return onSuccess(data);
        }).catch(error => {
            return onError(error);
        });
    }
}