import './styles/ReadoutScreen.css'
import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getReadoutDataBySerial, getSerialSearch } from '../api/WebHelper';
import { MContext } from '../session/SessionContext';
import DataRender from '../panels/RawDataRenderPanel';

const ConfigSection = (props) => {
    const { title, description, children, right } = props;
    return (
        <Row align="stretch">
            <Col span={8} className="options-section">
                {title && <h3 className='section-header'>{title}</h3>}
                {description && <div className='mt-0 mb-1'>{description}</div>}
                {children}
            </Col>
            <Col span={4} className="right-options-section">
                {right && right}
            </Col>
        </Row>
    );
}

const RowConfigSection = (props) => {
    const { titleLeft, titleRight, left, right } = props;
    return (
        <Row align="stretch" justify="space-between">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="dual-config-row">
                {left && <div className='options-section'>
                    {titleLeft && <h3 className='section-header'>{titleLeft}</h3>}
                    {left}
                </div>}

            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="dual-config-row">
                {right && <div className='options-section'>
                    {titleRight && <h3 className='section-header'>{titleRight}</h3>}
                    {right}
                </div>}
            </Col>
        </Row>
    );
}

const InputField = React.forwardRef(({ type, onContentChange }, ref) => {
    return (
        <div className='search-wrapper'>
            <input ref={ref} type={type} className='search-input force-capital' onChange={onContentChange} />
            <button className='search-button' type="submit">Search</button>
        </div>
    );
});

const RadioField = React.forwardRef(({ id, label, group, checked = false }, ref) => {
    return (
        <div>
            <input id={id} ref={ref} type="radio" name={group} className='input-radio' defaultChecked={checked} />
            <label className='input-label' htmlFor={id} >{label}</label>
        </div>
    );
});

const CheckField = React.forwardRef(({ id, label, group, onChange, checked = false }, ref) => {
    return (
        <div>
            <input id={id} ref={ref} type="checkbox" name={group} className='input-radio' defaultChecked={checked} onChange={onChange} />
            <label className='input-label' htmlFor={id} >{label}</label>
        </div>
    );
});

const AutoCompleteSuggestionBox = ({ textValue, onClick }) => {
    return (<li onClick={(e) => onClick(textValue)} className="suggestions-item" >{textValue}</li>)
}

const RenderAutoCompleteBox = ({ suggestionList, onSuggestionUsed }) => {

    const onSuggestionClicked = (suggestion) => {
        onSuggestionUsed(suggestion);
    }
    console.log(suggestionList);
    if (suggestionList.length === 0) {
        return (
            <ul className='suggestion-box'>
                <li className='suggestion-seperator'></li>
                <li className='suggestion-nothing-found'>Nothing found</li>
            </ul>
        )
    }
    else {
        return (
            <ul className='suggestion-box'>
                <li className='suggestion-seperator'></li>
                {
                    suggestionList.map((item, key) => {
                        return (<AutoCompleteSuggestionBox key={key} textValue={item} onClick={onSuggestionClicked} />);
                    })
                }
            </ul>
        )
    }
}

const SearchParameters = ({ onDataRetrieved, filter }) => {
    const [searchError, setSearchError] = React.useState("");
    const [suggestions, setSuggestions] = React.useState(null);
    const contextValue = useContext(MContext);
    const authObject = contextValue.state.authentication;
    const rawSearchData = contextValue.state.rawSearchData;
    const connectRef = React.useRef();
    const clarityRef = React.useRef();
    const bambooRef = React.useRef();
    const tifaRef = React.useRef();
    const fredRef = React.useRef();
    const woodyRef = React.useRef();
    const tomRef = React.useRef();
    const serialRef = React.useRef();

    useEffect(() => {
        const { settings } = rawSearchData;

        if (!rawSearchData || !settings) {
            console.log("no search data available");
        }
        else {
            console.log(rawSearchData);
            let modelId = settings.product;
            switch (modelId) {
                case "DR009":
                    connectRef.current.checked = (true);
                    break;
                case "DP001E":
                    bambooRef.current.checked = (true);
                    break;
                case "DP002E":
                    clarityRef.current.checked = (true);
                    break;
                case "DP003E":
                    tifaRef.current.checked = (true);
                    break;
                case "DP004E":
                    fredRef.current.checked = (true);
                    break;
                case "DP005E":
                    woodyRef.current.checked = (true);
                    break;
                case "DP011E":
                    tomRef.current.checked = (true);
                    break;
            }

            serialRef.current.value = settings.serial;
        }
    }, [rawSearchData]);

    const onSearchSuccess = data => {
        onDataRetrieved(data);
        setSearchError("");
    }

    const onSearchError = error => {
        onDataRetrieved(null);
        setSearchError(error.message);
    }

    const startProductSearch = () => {
        const serial = serialRef.current.value;

        console.log("searching for serial: " + serial);

        let checkedDevice;
        if (bambooRef.current.checked) {
            checkedDevice = "DP001E";
        }
        else if (connectRef.current.checked) {
            checkedDevice = "DR009";
        }
        else if (clarityRef.current.checked) {
            checkedDevice = "DP002E";
        }
        else if (tifaRef.current.checked) {
            checkedDevice = "DP003E";
        }
        else if (fredRef.current.checked) {
            checkedDevice = "DP004E";
        }
        else if (woodyRef.current.checked) {
            checkedDevice = "DP005E";
        }
        else if (tomRef.current.checked) {
            checkedDevice = "DP011E";
        }
        else {
            setSearchError("Select a device.");
            return;
        }
        console.log(checkedDevice);

        let bodyObject = {
            settings: {
                product: checkedDevice,
                serial: serial
            },
            options: filter
        }

        contextValue.setRawSearchData(bodyObject);
        getReadoutDataBySerial(authObject.access_token, JSON.stringify(bodyObject), onSearchSuccess, onSearchError);
    }

    const handleSubmit = e => {
        e.preventDefault();

        startProductSearch();
    }

    const onAutoCompleteSuccess = data => {
        setSuggestions(data);
    }

    const onAutoCompleteError = error => {
        setSuggestions(null);
    }

    const performAutoSearch = e => {
        const serial = serialRef.current.value;

        let checkedDevice;
        if (bambooRef.current.checked) {
            checkedDevice = "DP001E";
        }
        else if (connectRef.current.checked) {
            checkedDevice = "DR009";
        }
        else if (clarityRef.current.checked) {
            checkedDevice = "DP002E";
        }
        else if (tifaRef.current.checked) {
            checkedDevice = "DP003E";
        }
        else if (fredRef.current.checked) {
            checkedDevice = "DP004E";
        }
        else if (woodyRef.current.checked) {
            checkedDevice = "DP005E";
        }
        else if (tomRef.current.checked) {
            checkedDevice = "DP011E";
        }
        else {
            // simply dont do autocomplete
            console.log("Could not start autocomplete");
            return;
        }

        if (serial === null || serial === '') {
            console.log("empty textbox");
            setSuggestions(null);
            return;
        }
        else {
            if (serial.length <= 3) {
                setSuggestions(null);
                return; //not long enough yet, box requires atleast 3 chars to start searching
            }
        }

        console.log(`Performing a ${checkedDevice} search for ${serial}`);

        let bodyObject = {
            product: checkedDevice,
            query: serial.toUpperCase()
        }
        getSerialSearch(authObject.access_token, JSON.stringify(bodyObject), onAutoCompleteSuccess, onAutoCompleteError);
    }

    const overwriteTextBox = s => {
        serialRef.current.value = s;

        setSuggestions(null);
        startProductSearch();
    }

    return (
        <form onSubmit={handleSubmit}>
            <Row align="stretch" className="main-row">
                <Col span={3}>
                    <h4 className='section-header mt-1'>Product</h4>
                    <RadioField id="connect" ref={connectRef} label="Connect" group="product" checked={true} />
                    <RadioField id="clarity" ref={clarityRef} label="Clarity" group="product" />
                    <RadioField id="bamboo" ref={bambooRef} label="Wall-E & Eve" group="product" />
                    <RadioField id="tifa" ref={tifaRef} label="Tifa" group="product" />
                    <RadioField id="fred" ref={fredRef} label="Fred & Barney" group="product" />
                    <RadioField id="woody" ref={woodyRef} label="Woody & Buzz" group="product" />
                    <RadioField id="tom" ref={tomRef} label="Tom & Jerry" group="product" />
                </Col>
                <Col span={9}>
                    <h4 className='section-header mt-1'>Serial Search</h4>
                    <InputField ref={serialRef} onContentChange={performAutoSearch} />
                    {searchError && <div className='search-error'>{searchError}</div>}
                    <div className='suggestions-target'>
                        {suggestions && <RenderAutoCompleteBox suggestionList={suggestions} onSuggestionUsed={overwriteTextBox} />}
                    </div>
                </Col>
            </Row>
        </form>
    );
};

const OptionsParameters = ({ onFormChanged }) => {
    const contextValue = useContext(MContext);
    const rawSearchData = contextValue.state.rawSearchData;

    function contains(array, name) {
        let returnArray = [];
        for (let role of array) {
            if (role === name) {
                returnArray.push(role);
            }
        }
        if (returnArray.length === 0) {
            return false;
        }
        return true;
    }

    const earLeftRef = React.useRef();
    const earRightRef = React.useRef();
    const cradleRef = React.useRef();
    const dongleRef = React.useRef();

    const countersRef = React.useRef();
    const eventsRef = React.useRef();

    const firmwareRef = React.useRef();
    const voicePromptRef = React.useRef();
    const mobileOsRef = React.useRef();
    const occuranceLogRef = React.useRef();

    useEffect(() => {
        if (!rawSearchData || !rawSearchData.options) {
            console.log("no options data available");
        }
        else {
            const { parts, diagnostics, other } = rawSearchData.options;

            if (contains(parts, "left")) {
                earLeftRef.current.checked = true;
            }

            if (contains(parts, "right")) {
                earRightRef.current.checked = true;
            }

            if (contains(parts, "cradle")) {
                cradleRef.current.checked = true;
            }

            if (contains(parts, "dongle")) {
                dongleRef.current.checked = true;
            }

            if (contains(diagnostics, "counters")) {
                countersRef.current.checked = true;
            }

            if (contains(diagnostics, "events")) {
                eventsRef.current.checked = true;
            }

            if (contains(other, "firmware")) {
                firmwareRef.current.checked = true;
            }

            if (contains(other, "voiceprompt")) {
                voicePromptRef.current.checked = true;
            }

            if (contains(other, "mobile")) {
                mobileOsRef.current.checked = true;
            }

            if (contains(other, "logs")) {
                occuranceLogRef.current.checked = true;
            }

            onFormChanged(rawSearchData.options);
        }
    }, [rawSearchData]);

    const handleInputChange = event => {
        //assemble an object

        let changedOptionsObject = {
            parts: [],
            diagnostics: [],
            other: []
        };

        if (earLeftRef.current.checked) {
            changedOptionsObject.parts.push("left");
        }

        if (earRightRef.current.checked) {
            changedOptionsObject.parts.push("right");
        }

        if (cradleRef.current.checked) {
            changedOptionsObject.parts.push("cradle");
        }

        if (dongleRef.current.checked) {
            changedOptionsObject.parts.push("dongle");
        }

        if (countersRef.current.checked) {
            changedOptionsObject.diagnostics.push("counters");
        }

        if (eventsRef.current.checked) {
            changedOptionsObject.diagnostics.push("events");
        }

        if (firmwareRef.current.checked) {
            changedOptionsObject.other.push("firmware");
        }

        if (voicePromptRef.current.checked) {
            changedOptionsObject.other.push("voiceprompt");
        }

        if (mobileOsRef.current.checked) {
            changedOptionsObject.other.push("mobile");
        }

        if (occuranceLogRef.current.checked) {
            changedOptionsObject.other.push("logs");
        }

        onFormChanged(changedOptionsObject);
    }

    return (
        <form>
            <Row align="stretch" className="main-row">
                <Col span={3}>
                    <h4 className='section-header mt-1'>Product Parts</h4>
                    <CheckField id="earbud_left" ref={earLeftRef} label="Earbud Left" group="product_part" onChange={handleInputChange} />
                    <CheckField id="earbud_right" ref={earRightRef} label="Earbud Right" group="product_part" onChange={handleInputChange} />
                    <CheckField id="cradle" ref={cradleRef} label="Cradle" group="product_part" onChange={handleInputChange} />
                    <CheckField id="dongle" ref={dongleRef} label="Dongle" group="product_part" onChange={handleInputChange} />
                </Col>
                <Col span={3}>
                    <h4 className='section-header mt-1'>Diagnostics</h4>
                    <CheckField id="counters_diag" ref={countersRef} label="Counters" group="diagnostics" onChange={handleInputChange} />
                    <CheckField id="events_diag" ref={eventsRef} label="Events" group="diagnostics" onChange={handleInputChange} />
                </Col>
                <Col span={6}>
                    <h4 className='section-header mt-1'>Other</h4>
                    <CheckField id="firmware_other" ref={firmwareRef} label="Firmware" group="other" onChange={handleInputChange} />
                    <CheckField id="voiceprompt_other" ref={voicePromptRef} label="Voiceprompt" group="other" onChange={handleInputChange} />
                    <CheckField id="mobile_os_other" ref={mobileOsRef} label="Mobile Operating System" group="other" onChange={handleInputChange} />
                    <CheckField id="occurance_log_other" ref={occuranceLogRef} label="Occurance Logs" group="other" onChange={handleInputChange} />
                </Col>
            </Row>
        </form>
    );
};

const ReadoutScreen = (props) => {
    const navigate = useNavigate();
    const [allow, setAllow] = useState(false);
    const [data, setData] = useState(null);
    const [dataSummery, setDataSummery] = useState(null);
    const [productInfo, setProductInfo] = useState(null);
    const [filter, setFilter] = useState({
        parts: [],
        diagnostics: [],
        other: []
    });
    const contextValue = useContext(MContext);
    const authObject = contextValue.state.authentication;

    useEffect(() => {
        if (!authObject.username) {
            console.log("Not signed in.");
            navigate("/authenticate?returnUrl=/readout");
        }
        else {
            setAllow(true);
        }
    }, [authObject, navigate]);


    useEffect(() => {
        if (data) {
            const first_seen = new Date(data.first_seen);
            const first_seen_string = `${first_seen.toLocaleDateString()} at ${first_seen.toLocaleTimeString()}`;

            const last_seen = new Date(data.last_seen);
            const last_seen_string = `${last_seen.toLocaleDateString()} at ${last_seen.toLocaleTimeString()}`;


            setDataSummery(`First saw this product on ${first_seen_string}, the last time it was seen was ${last_seen_string}.`);

            const { serial_left, serial_right, serial_cradle, firmware, voiceprompt } = data;
            let firmware_text;
            if (firmware) {
                firmware_text = `Firmware versions: Left v${firmware.left}, Right v${firmware.right} and Cradle v${firmware.cradle}`;
            }

            let voiceprompt_text;
            if (voiceprompt) {
                voiceprompt_text = `Voiceprompt language: ${voiceprompt}`;
            }

            setProductInfo(`Serial left: ${serial_left}, Serial right: ${serial_right}, Serial cradle: ${serial_cradle} ${firmware_text ? "<br>" + firmware_text : ""} ${voiceprompt_text ? "<br>" + voiceprompt_text : ""}`);
        }
        else {
            setDataSummery(null);
            setProductInfo(null);
        }
    }, [data]);

    const onSearchParamsSubmitted = (data) => {
        setData(data);
    };

    const onOptionsFormChanged = (options) => {
        setFilter(options);
    };

    const createDataSummery = (dataSummery) => {
        return (
            <div className='data-summery'>
                <h3 className='section-header'>Data retrieved</h3>
                <div className='mt-1' dangerouslySetInnerHTML={{ __html: dataSummery }}>

                </div>
            </div>
        )
    }

    const createProductInfo = (info) => {
        return (
            <div className='data-summery'>
                <h3 className='section-header'>Product Info</h3>
                <div className='mt-1' dangerouslySetInnerHTML={{ __html: info }}>

                </div>
            </div>
        )
    }

    if (!allow) {
        return (
            <div className='center font-heavy text-dark'>
                Not signed in, you will be redirected...
            </div>
        );
    }
    else {
        return (
            <Row align="stretch" className="main-row">
                <Col span={2}
                    xs={12} sm={12} md={12} lg={2} xl={2} className="side-bar">
                    <div className="filter-bar">
                        <h3 className='mt-0'>Prodcuct Reader</h3>
                        <div>Use this page to find raw data of a product by serial</div>
                    </div>
                </Col>
                <Col span={10}
                    xs={12} sm={12} md={12} lg={10} xl={10}>
                    <div className='empty-wrapper'>
                        <RowConfigSection titleLeft="Search Parameters" titleRight="Search Options"
                            left={
                                <SearchParameters onDataRetrieved={onSearchParamsSubmitted} filter={filter} />
                            }
                            right={
                                <OptionsParameters onFormChanged={onOptionsFormChanged} />
                            } />

                        {<RowConfigSection title="Search Parameters"
                            left={
                                dataSummery && createDataSummery(dataSummery)
                            }
                            right={
                                productInfo && createProductInfo(productInfo)
                            } />}
                        {data && <DataRender data={data} />}
                    </div>
                </Col>
            </Row>
        );
    }
}

export default ReadoutScreen;