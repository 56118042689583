import './styles/ConfigScreen.css'
import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';
import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { MContext } from '../session/SessionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faTrash, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import RoleParser from '../security/roleParser';
import { deleteUser, getAllUsers, registerNewUser, resetUserPsswd } from '../api/WebHelper';


const ConfigSection = (props) => {
    const { title, description, children } = props;
    return (
        <div className='graph-screen config-section'>
            <h3 className='mt-0 black-text'>{title}</h3>
            <div className='mt-0 mb-1'>{description}</div>
            {children}
        </div>
    );
}

const RenderSelectedUser = (props) => {
    const { userList, selectedUser, handleDelete, handlePasswordReset, message } = props;
    const usernameRef = useRef();
    const fullNameRef = useRef();
    const emailRef = useRef();
    const isAdminRef = useRef();

    const handleSubmit = e => {
        e.preventDefault();
        console.log("pressed save")
    };

    const InputField = React.forwardRef(({ label, type, disabled = false, defaultValue = '' }, ref) => {
        return (
            <div className='user-config-section black-text'>
                <label className='login-label black-text' >{label}</label>
                <input ref={ref} type={type} className='login-input black-text' disabled={disabled} defaultValue={defaultValue} />
            </div>
        );
    });

    let user = userList.find(x => x.username === selectedUser);
    const isAdmin = user.roles.includes("ROLE_ADMIN");
    return (
        <div>
            <div className=''>
                <div className='col-edit-right col-edit-button user-delete black-text' id={'delete-user'} onClick={e => handleDelete(user.username)}>Delete user <FontAwesomeIcon className='blue' icon={faTrash} /></div>
                <div className='col-edit-right col-edit-button user-delete black-text' id={'reset-password-user'} onClick={e => handlePasswordReset(user.username)}>Reset Password <FontAwesomeIcon className='blue' icon={faKey} /></div>
                <div className='clear-both'></div>
            </div>
            {message && <div className='col-edit-right color-red'>{message}</div>}
            <div className='clear-both'></div>
            <form className='user-edit-box' onSubmit={handleSubmit} >
                <InputField ref={usernameRef} label="Username" type="text" defaultValue={user.username} />
                <InputField ref={fullNameRef} label="Full Name" type="text" defaultValue={user.full_name} />
                <InputField ref={emailRef} label="Email" type="text" defaultValue={user.email} />
                <div className='user-config-section'>
                    <label className='col-edit-form-label-inline black-text' >Is Admin?</label>
                    <input ref={isAdminRef} type={"checkbox"} className='edit-visible' defaultChecked={isAdmin} />
                </div>
                <div className='user-config-section'>
                    <label className='login-label black-text' >Created on</label>
                    <input type={"text"} className='login-input' disabled={true} value={new Date(user.created_at).toLocaleString()} />
                </div>
                <div>
                    <button className='login-button' type="submit" disabled={false}>{"Save Changes"}</button>
                </div>
            </form>
        </div>
    );
};

const RenderCreateUser = (props) => {
    const { handleCreateAccount } = props;
    const fullNameRef = useRef();
    const emailRef = useRef();
    const isAdminRef = useRef();
    const [message, setMessage] = useState(null);

    const handleSubmit = e => {
        e.preventDefault();

        let fullName = fullNameRef.current.value;
        let email = emailRef.current.value;
        let isAdmin = isAdminRef.current.checked;

        if (!fullName || !email) {
            setMessage("Please fill in both fields");
            return;
        }

        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            setMessage("Not an email.");
            return;
        }

        let newAccountObject = {
            name: fullName,
            email: email,
            user_roles: [

            ]
        }

        if (isAdmin) {
            newAccountObject.user_roles.push("ROLE_ADMIN");
        }

        setMessage("Created account. The user will receive an email shortly.");
        handleCreateAccount(newAccountObject);
    };

    const InputField = React.forwardRef(({ label, type, disabled = false, defaultValue = '' }, ref) => {
        return (
            <div className='user-config-section'>
                <label className='login-label black-text' >{label}</label>
                <input ref={ref} type={type} className='login-input' disabled={disabled} defaultValue={defaultValue} />
            </div>
        );
    });

    return (
        <div>
            {message && <div className='col-edit-right color-red'>{message}</div>}
            <form className='user-edit-box' onSubmit={handleSubmit} >
                <InputField ref={fullNameRef} label="Full Name" type="text" />
                <InputField ref={emailRef} label="Email" type="text" />
                <div className='user-config-section'>
                    <label className='col-edit-form-label-inline black-text' >Is Admin?</label>
                    <input ref={isAdminRef} type={"checkbox"} className='edit-visible' />
                </div>
                <div>
                    <button className='login-button' type="submit" disabled={false}>{"Invite User"}</button>
                </div>
            </form>
        </div>
    );
};

const RenderUserList = (props) => {
    const { authObject } = props;
    const [message, setMessage] = useState(null);
    const [userList, setUserList] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [createNew, setCreateNew] = useState(false);
    const [deleteTapped, setDeleteTapped] = useState(0);

    useEffect(() => {
        getAllUsers(authObject.access_token, onUsersDownloaded, onUsersDownloadFail);
    }, []);

    useEffect(() => {
        setMessage(null);
    }, [selectedUser]);

    const onUsersDownloaded = (userList) => {
        setSelectedUser(null);
        setUserList(userList);
    };

    const onUsersDownloadFail = (error) => {
        console.error(error);
    };

    const onUserDeleteSuccess = success => {
        setMessage("User has been deleted.");
        setDeleteTapped(0);
        setTimeout(() => {
            getAllUsers(authObject.access_token, onUsersDownloaded, onUsersDownloadFail);
        }, 1000);
    }

    const onUserDeleteError = error => {
        console.error("userDelete", error);
    }

    const onUserPasswdResetSucces = success => {
        setMessage("Password has been reset. User has mail");
    }

    const onUserPasswdResetError = error => {
        setMessage("Could not reset password, message: " + error);
    }

    const onCreateUserSuccess = success => {
        console.log("user created");
    }
    const onCreateUserError = error => {
        console.log("user create failed.");
    };

    if (!authObject.roles.includes("ROLE_ADMIN")) {
        return (<div>Not allowed</div>)
    }

    const onCreateNewUser = e => {
        setSelectedUser(null);
        setCreateNew(true);
    }

    const handleDelete = e => {
        let currentAllowTime = Date.now() - 5000;

        if (deleteTapped >= currentAllowTime) {
            //delete: 
            console.log("deleting: ", e);
            deleteUser(authObject.access_token, e, onUserDeleteSuccess, onUserDeleteError);
            
        }
        else {
            setDeleteTapped(Date.now());
            setMessage("Press Delete again to permanently remove.");
        }
    }

    const handlePasswordReset = e => {
        console.log("resetting password: ", e);
        resetUserPsswd(authObject.access_token, e, onUserPasswdResetSucces, onUserPasswdResetError);
    }

    const handleCreateAccount = e => {
        registerNewUser(authObject.access_token, JSON.stringify(e), onCreateUserSuccess, onCreateUserError);
    }

    if (userList != null) {
        return (
            <Row align="stretch" className="main-row">
                <Col span={6}
                    xs={12} sm={12} md={12} lg={6} xl={6}>
                    <h3 className='black-text'>User List</h3>
                    <div>
                        <div className='col-edit-left col-edit-button user-delete black-text' id={'delete-user'} onClick={onCreateNewUser} >Create user <FontAwesomeIcon className='blue' icon={faUserPlus} /></div>
                        <div className='clear-both'></div>
                    </div>
                    <ul className='user-list-wrapper'>
                        {userList.map((item, key) => {
                            return (<UserListObject key={key} userObject={item} onObjectClicked={setSelectedUser} active={item.username === selectedUser} />);
                        })}
                    </ul>
                </Col>
                {selectedUser !== null && <Col span={6}
                    xs={12} sm={12} md={12} lg={6} xl={6}>
                    <h3 className='black-text'>Edit User</h3>
                    <div>
                        <RenderSelectedUser selectedUser={selectedUser} userList={userList} handlePasswordReset={handlePasswordReset} handleDelete={handleDelete} message={message} />
                    </div>
                </Col>}
                {selectedUser === null && createNew && <Col span={6}
                    xs={12} sm={12} md={12} lg={6} xl={6}>
                    <h3 className='black-text'>Create User</h3>
                    <div>
                        <RenderCreateUser handleCreateAccount={handleCreateAccount} />
                    </div>
                </Col>}
            </Row>
        )
    }

    return (
        <div>Loading users...</div>
    )
}

const UserListObject = (props) => {
    const { userObject, onObjectClicked, active } = props;
    const { full_name, email, username } = userObject;


    const onItemClick = (username) => {
        onObjectClicked(username);
    }

    if (active) {
        return (
            <li className='user-list-item user-selected' onClick={(e) => onItemClick(null)} id={username}>
                <div className='user-list-item-name'>
                    {full_name}
                </div>
                <div>
                    {email}
                </div>
            </li>
        );
    }
    else {
        return (
            <li className='user-list-item' onClick={(e) => onItemClick(username)} id={username}>
                <div className='user-list-item-name black-text'>
                    {full_name}
                </div>
                <div className='black-text'>
                    {email}
                </div>
            </li>
        );
    }
}

const ConfigScreen = (props) => {
    const navigate = useNavigate();
    const [allow, setAllow] = useState(false);
    const contextValue = useContext(MContext);
    const authObject = contextValue.state.authentication;
    const permissions = RoleParser.create(authObject);
    // permissions.contains("ROLE_ADMIN")

    useEffect(() => {
        if (!authObject.username) {
            console.log("Not signed in.");
            navigate("/authenticate?returnUrl=/config");
        }
        else {
            if (permissions.contains("ROLE_ADMIN")) {
                setAllow(true);
            }
        }
    }, [authObject, navigate]);

    if (!allow) {
        return (
            <div className='center font-heavy text-dark'>
                You are not allowed in here.
            </div>
        );
    }
    else {
        return (
            <Row align="stretch" className="main-row">
                <Col span={2}
                    xs={12} sm={12} md={12} lg={2} xl={2} className="side-bar">
                    <div className="filter-bar">
                        <h3 className='mt-0'>System Config</h3>
                        <div>Change settings that relate to how the X-Ray platform will function.</div>
                    </div>
                </Col>
                <Col span={10}
                    xs={12} sm={12} md={12} lg={10} xl={10}>
                    <div className="graph-screen-wrapper">
                        <ConfigSection title="Users" description="Manage users in the system">
                            <RenderUserList authObject={authObject} />
                        </ConfigSection>
                        <ConfigSection title="Device Config" description="Add or remove product definitions from the database">
                            <div>Not yet implemented</div>
                        </ConfigSection>
                        <ConfigSection title="External Apps" description="Configure what apps are allowed to upload diagnostics data">
                            <div>Not yet implemented</div>
                        </ConfigSection>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default ConfigScreen;