import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';
import React, { useEffect, useState, useContext } from "react";
import { MContext } from '../session/SessionContext';
import { getFrequency } from '../api/WebHelper';
import { VictoryTheme, VictoryLabel, VictoryChart, VictoryLine, VictoryAxis, VictoryVoronoiContainer, VictoryTooltip } from "victory";

function getDates(startDate, daysBack) {
    let returnArray = [];
    const startDateObj = new Date(startDate);
    let changeDate = startDateObj;
    returnArray.push(startDateObj.toDateString());
    for (let i = 1; i < daysBack; i++) {
        changeDate.setDate(startDateObj.getDate() - 1);
        returnArray.push(changeDate.toDateString());
    }

    return returnArray;
}

function calculateWeekNumber(date) {
    var d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
}

const FrequencyGraphDays = (props) => {
    const { frequencyData, appKey } = props;
    let [data, setData] = useState(null);

    useEffect(() => {
        let dataArray = {};
        // calculate the frequency data for the key.
        for (let log of frequencyData) {
            const date = new Date(log.DATETIME);
            const dateString = date.toDateString();
            dataArray[dateString] = dataArray[dateString] ? dataArray[dateString] + 1 : 1;
        }

        let dataList = [];

        const objects = Object.entries(dataArray);

        // create an array with all of the past days.
        const days = getDates(objects[objects.length - 1][0], 30);
        days.reverse();
        for (let day of days) {
            let daya = dataArray[day];
            if (!daya) {
                daya = 0;
            }
            dataList.push({
                x: new Date(day).toLocaleDateString(),
                y: daya,
            });
        }

        setData(dataList);
    }, [frequencyData, appKey, props]);
    //todo: add filter settings and view settings, view per day, per hour, per minute.

    return (
        <Col span={12}
            xs={12} sm={6} md={6} lg={4} xl={4} xxl={4} xxxl={4} xxxxl={3}>
            <div className="graph-screen-wrapper">
                <div className="graph-screen">
                    <h4 className='section-header black-text'>{appKey}</h4>
                    <div>
                        <VictoryChart
                            height={450}
                            width={450}
                            scale={{ x: 'linear' }}
                            theme={VictoryTheme.material}

                            containerComponent={
                                <VictoryVoronoiContainer
                                    mouseFollowTooltips
                                    voronoiDimension="x"
                                />
                            }
                            domainPadding={20}>
                            <VictoryAxis
                                labelComponent={<VictoryLabel
                                    angle={45}
                                />}
                                label="30 days"
                                axisLabelComponent={<VictoryLabel dy={26} />}
                                fixLabelOverlap
                            />

                            <VictoryAxis
                                dependentAxis
                                label="Data uploads per day"
                                axisLabelComponent={<VictoryLabel dy={-26} />}
                            />

                            <VictoryLine
                                style={{
                                    parent: { border: "1px solid #ccc" }
                                }}
                                data={data}
                                interpolation="natural"
                                labelComponent={<VictoryTooltip
                                    cornerRadius={5}
                                    pointerLength={20}
                                    flyoutStyle={{
                                        stroke: "tomato"
                                    }}
                                />}
                                labels={({ datum }) => `  ${datum.y} \r\n${datum.x} `}
                            />
                            {/* <VictoryScatter
                                style={{ data: { fill: "#c43a31" } }}
                                size={7}
                                data={data}
                                labelComponent={<VictoryTooltip
                                    cornerRadius={5}
                                    pointerLength={20}
                                    flyoutStyle={{
                                        stroke: "tomato"
                                    }}
                                />}
                                labels={({ datum }) => `  ${datum.y}  `}
                            /> */}
                        </VictoryChart>
                    </div>
                </div>
            </div>
        </Col>
    );
}

const FrequencyGraphWeek = (props) => {
    const { frequencyData, appKey } = props;
    let [data, setData] = useState(null);

    useEffect(() => {
        let dataArray = {};
        // calculate the frequency data for the key.
        for (let log of frequencyData) {
            const date = new Date(log.DATETIME);
            const year = date.getUTCFullYear();
            const weekNumber = calculateWeekNumber(date);
            const index = year + "," + weekNumber;
            dataArray[index] = dataArray[index] ? dataArray[index] + 1 : 1;
        }

        let dataList = [];
        const today = new Date();
        const weekCounter = 52;
        const currentWeek = today.getUTCFullYear() + "," + calculateWeekNumber(today);
        const [year, week] = currentWeek.split(",");
        const nYear = Number(year);
        const nWeek = Number(week);

        for (let i = 0; i < weekCounter; i++) {
            let targetYear = nYear - ((nWeek - i < 1) ? 1 : 0); // for when we go into the previous year.
            let targetWeek = (nWeek - i < 1) ? 52 + (nWeek - i) : nWeek - i;
            const weekIndex = `${targetYear},${targetWeek}`;
            const data = dataArray[weekIndex];

            if (data) {
                dataList.push({
                    x: weekIndex,
                    y: data,
                });
            }
            else {
                dataList.push({
                    x: weekIndex,
                    y: 0,
                });
            }
        }

        dataList.reverse();
        setData(dataList);
    }, [frequencyData, appKey, props]);
    //todo: add filter settings and view settings, view per day, per hour, per minute.

    return (
        <Col span={6}
            >
            <div className="graph-screen-wrapper">
                <div className="graph-screen">
                    <h4 className='section-header black-text'>{appKey}</h4>
                    <div>
                        <VictoryChart
                            height={450}
                            width={900}
                            scale={{ x: 'linear' }}
                            theme={VictoryTheme.material}

                            containerComponent={
                                <VictoryVoronoiContainer
                                    mouseFollowTooltips
                                    voronoiDimension="x"
                                />
                            }
                            domainPadding={20}>
                            <VictoryAxis
                                labelComponent={<VictoryLabel
                                    angle={45}
                                />}
                                label="52 weeks"
                                axisLabelComponent={<VictoryLabel dy={26} />}
                                fixLabelOverlap
                            />

                            <VictoryAxis
                                dependentAxis
                                label="Data uploads per week"
                                axisLabelComponent={<VictoryLabel dy={-26} />}
                            />

                            <VictoryLine
                                style={{
                                    parent: { border: "1px solid #ccc" }
                                }}
                                data={data}
                                interpolation="stepBefore"
                                labelComponent={<VictoryTooltip
                                    cornerRadius={5}
                                    pointerLength={20}
                                    flyoutStyle={{
                                        stroke: "tomato"
                                    }}
                                />}
                                labels={({ datum }) => `  ${datum.y} \r\n${datum.x} `}
                            />
                            {/* <VictoryScatter
                                style={{ data: { fill: "#c43a31" } }}
                                size={7}
                                data={data}
                                labelComponent={<VictoryTooltip
                                    cornerRadius={5}
                                    pointerLength={20}
                                    flyoutStyle={{
                                        stroke: "tomato"
                                    }}
                                />}
                                labels={({ datum }) => `  ${datum.y}  `}
                            /> */}
                        </VictoryChart>
                    </div>
                </div>
            </div>
        </Col>
    );
}

const FrequencyPanel = (props) => {
    const contextValue = useContext(MContext);
    const authObject = contextValue.state.authentication;

    // downloaded states:
    const [appKeys, setAppKeys] = useState([]);
    const [keyData, setKeyData] = useState(null);

    const onDataDownloaded = (data) => {
        const { keys, logs } = data;

        if (keys && logs) {
            let finalKeyData = {};
            for (let logObj of logs) {
                if (!finalKeyData[logObj.PACKAGE]) {
                    finalKeyData[logObj.PACKAGE] = [];
                }
                finalKeyData[logObj.PACKAGE].push(logObj);
            }
            // process data to contain occurance per key.
            setKeyData(finalKeyData);
            setAppKeys(keys);
        }
    };

    const onDataFail = (error) => {
        console.error(error);
    }

    // download the frequency data, then render a graph per app key.
    useEffect(() => {
        if (keyData === null) {
            getFrequency(authObject.access_token, JSON.stringify({ start: 0, stop: 0 }), onDataDownloaded, onDataFail);
        }
    }, [keyData, appKeys]);

    if (appKeys.length === 0) {
        return (
            <Row align="stretch" className="main-row">
                <Col span={12}
                    xs={12} sm={6} md={6} lg={4} xl={4} xxl={4} xxxl={4} xxxxl={3}>
                    <div className="graph-screen-wrapper">
                        <div className="graph-screen">
                            No frequency data available
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    return (
        <div>
            <Row align="stretch" className="main-row">
                {appKeys.map((item, key) => {
                    return <FrequencyGraphDays key={key} frequencyData={keyData[item]} appKey={item} />
                })}

            </Row>
            <Row align="stretch" className="main-row">
                {appKeys.map((item, key) => {
                    return <FrequencyGraphWeek key={key} frequencyData={keyData[item]} appKey={item} />
                })}

            </Row>
        </div>
    );
}

export default FrequencyPanel;