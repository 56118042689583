import './App.css';
import React, { useEffect, useState, useContext } from "react";
import { SessionContext, MContext } from './session/SessionContext';
import SessionLoader from './session/SessionLoader';

function App() {

  return (
    <SessionContext>
      <SessionLoader />
    </SessionContext>
  );
}

export default App;
