import './styles/Footer.css';
import { Row, Col } from 'xray-flex-grid';
import 'xray-flex-grid/lib/main.css';

const Footer = (props) => {

    return (
        <Row align="top" justify="center">
            <Col span={6}>
                <div className='footer-text center'>
                    Dopple B.V. &copy; 2022
                </div>
            </Col>
        </Row>
    );
}

export default Footer;